import { useCallback, useContext, useEffect, useRef, useState } from "react";
import {FcFolder, FcOpenedFolder} from "react-icons/fc";
import {BsFilePlusFill, BsFileText} from "react-icons/bs";
import {GiClick} from "react-icons/gi";
import {AiOutlineCloudDownload} from "react-icons/ai";
import animationData from '../../../resoureces/lottie/folder/Animation - 1697761495095.json';
import Lottie from "lottie-web";
import { userMenuList } from "../../../data/homePage/menu";
import React from "react";
import { useLocation } from "react-router-dom";
import { postAPI, putAPI } from "../../../data/data";
import styled from "styled-components";
import { type } from "@testing-library/user-event/dist/type";
import { fromUrlSafeBase64 } from "../../../config/base64";
import Cookies from "js-cookie";
import CryptoJS from 'crypto-js';
import { getCookie, getCookieKey, getCookieSite } from "../../common/cookies/cookiesAuth";
function LoadFunction(){

    const container = useRef(null);
    useEffect(() => {
        Lottie.loadAnimation({
          container: container.current,
          renderer: 'svg',
          loop: true,
          autoplay: true,
          animationData: animationData
        });
        
    }, [container]);
    return <>
        <div className="lottie_covers" style={{height:"100%"}} ref={container}></div>
    </>
}

function GuidFunction(){
    
   
    return <>
        <div className="load_container" >
            <div className="load_content">
                <FcFolder style={{}} />
                <div className="click_folder" style={{display:"inline-block",position:"relative"}}>
                    <GiClick style={{position: "absolute",color: "gray",bottom:" -19px",left: "-35px"}} />
                </div>
                <p style={{width: "100%", textAlign:"center", marginTop: "10px", fontSize: "25px"}}>Click on the folder</p>
            </div>
        </div>
    </>
}


const MenuStyle = styled.div`
    display:flex;
    justify-content: start;
    gap: 50px;
    .content-space.rights{
        width:100%;
    }
    .content-space.lefts{
        height: 500px;
        overflow:auto;
    }
    .content-space{
        width: 40%;
        padding: 25px 15px;
        border-radius: 5px;
        background-color: #fff;
    }
    .folder_list{
        width:100%;
    }
    .information-box{
        width:100%;
        height:100%;
        max-height: 400px;
        background-color:#fff;
    }
    label{
        cursor: pointer;
    }
    table{
        width:100%;
    }
    .btn_box{
        text-align:center;
        padding: 50px 0;
    }
`;

const SetData = ({props, menuList, typeList, menuGroup, setMenuList, setTable, site_id})=>{
    const [value, setValue] = useState({
        menu_idx : props?.menu_idx,
        menu_name : (!props) ? "" : props?.menu_name,
        menu_pre : (!props) ? ((menuGroup == null) ? null :  menuGroup) : ((menuGroup == null) ? null :  menuGroup),
        menu_order : (!props) ? 1 : props?.menu_order,
        menu_url : (!props) ? "" : props?.menu_url,
    });

    const submitBtn = (!props) ? "생성" : "수정";
    const typeIdx = (!props) ? 1 : props?.menu_type_idx;
    
    const onSubmit = (e)=>{
        e.preventDefault();
        const menu_type = e.target.menu_type.value;
        
        
        const dataType = async () => {
            if(!props){

                const data = {
                    ...value,
                    menu_type_idx: menu_type,
                    menu_depth : (value.menu_pre == 0 || value.menu_pre == null) ? 1 : 2,
                    site_id
                }
                const pull = await postAPI("/menu/setMenu",null, data);

                setTable(<GuidFunction />);
                
                setMenuList(pull.data.body);
            } else {
                const data = {
                    ...value,
                    menu_type_idx: menu_type,
                    menu_depth : (value.menu_pre == 0 || value.menu_pre == null) ? 1 : 2,
                    site_id
                }
                const put = await putAPI("/menu/setMenuEdit", data);
                setTable(<GuidFunction />);
                
                setMenuList(put.data.body);
            }
            //window.location.reload();
        };
        dataType();
    }
    return <>
        <form onSubmit={onSubmit}>
            <table>
                <tbody>
                <tr>
                    <th>메뉴명</th>
                    <td><input type="text" value={value.menu_name} onChange={(e)=>setValue({...value, menu_name :e.target.value})} /></td>
                </tr>
                <tr>
                    <th>상위메뉴</th>
                    <td>
                        <select defaultValue={value.menu_pre} onChange={(e)=>setValue({...value, menu_pre : e.target.value})}>
                            <option value={0}>선택안함</option>
                            {menuList.map((item, idx)=>(
                                <option key={idx} value={item.menu_idx}>{item.menu_name}</option>
                            ))}
                        </select>
                    </td>
                </tr>
                <tr>
                    <th>메뉴 순서</th>
                    <td><input type="number" min={1} value={value.menu_order} onChange={
                        (e)=>
                        setValue({...value, menu_order : (e.target.value < 1) ? 1 : e.target.value})
                        } /></td>
                </tr>
                <tr>
                    <th>메뉴 URL</th>
                    <td><input type="text" value={value.menu_url} onChange={(e)=>setValue({...value, menu_url :e.target.value})} /></td>
                </tr>
                <tr>
                    <th>타입</th>
                    <td>
                        {typeList.map((item, idx)=>(
                            <label key={idx} target={`type${idx}`}>
                                <input type="radio" value={item.menu_type_idx} name="menu_type" target={`type${idx}`} defaultChecked={(item.menu_type_idx == typeIdx) ? true : false} />
                                <span>{item.type_title}</span>
                            </label>
                        ))}
                        
                    </td>
                </tr>
                </tbody>
            </table>
            <div className="btn_box">
                <input type="submit" value={submitBtn} />
            </div>
        </form>
    </>
}


export default function MenuInsert(){
    /* 오른쪽 테이블 값 */
    const [table, setTable] = useState(<GuidFunction />);
    /* 클릭전에는 기본 레이아웃 클릭시 로딩창 */
    const [loadingFolder, setLoadingFolder] = useState(<GuidFunction />);
    const [dataLoading, setDataLoading] = useState(false);
    const [isFolder, setIsFolder] = useState(false);
    const [menuList, setMenuList] = useState(null);
    const [typeList, setTypeList] = useState();
    const folderRef = useRef(null);
    const location = useLocation();
    const containerRef = useRef(null);
    const infoRef = useRef(null);


    const site_id = getCookieSite("site_id");
    useEffect(()=>{
        const fetchData = async () => {
            const data = await postAPI("/menu/listArray",containerRef.current, {site_id});
            const type = await postAPI("/menu/typeList",null);
            setTypeList(type.data);
            setTimeout(()=>{

                setDataLoading(data.isResult);
            }, 1000)
            setMenuList(data.data);
        };
        fetchData();
    },[location]);

    const handleClick = useCallback((elem) => {
        const folder = folderRef.current.querySelectorAll("li");
        if(elem.classList.contains("more") && elem.classList.contains("active")){
            const menu_group = (!elem.getAttribute("data-group")) ? null : elem.getAttribute("data-group");

            setTable(<LoadFunction />);
            setTimeout(()=>{
                setTable(<SetData site_id={site_id} setTable={setTable} props={null} setMenuList={setMenuList} menuGroup={menu_group} menuList={menuList} typeList={typeList} />);
            }, 1000)






        }else if(elem.classList.contains("active") && !elem.classList.contains("all")){
            const menu_idx = parseInt(elem.getAttribute("data-idx"));
            const menu_order = parseInt(elem.getAttribute("data-order"));
            const menu_group = (!elem.getAttribute("data-group")) ? null : elem.getAttribute("data-group");
            
            const obj = {
                menu_idx
            }
            const dataType = async () => {
                const data = await postAPI("/menu/type",null, obj);
                setTable(<LoadFunction />);
                setTimeout(()=>{
                    setTable(<SetData site_id={site_id} setTable={setTable} setMenuList={setMenuList} menuGroup={menu_group} props={{...data.data, menu_order: menu_order }} menuList={menuList} typeList={typeList} />);
                }, 1000)
            };
            dataType();

            
        }




        folder.forEach((el, i)=>{
            el.classList.remove("active");
        })
        elem.classList.add("active");
    }, [folderRef, menuList, dataLoading, isFolder]);



    useEffect(()=>{
        
        if (!menuList && !dataLoading) return;
        const folder = folderRef.current.querySelectorAll("li");
       

        const timeFunction = ()=>{
            setIsFolder(false);
            setLoadingFolder(<LoadFunction />);
            setTimeout(()=>{
                setIsFolder(true);
            }, 1300)
        }

        
        folder.forEach((elem, idx) => {

            // elem.setAttribute('draggable', 'true');
            // elem.addEventListener('dragstart', (e) => {
            //     e.dataTransfer.setData('text/plain', idx);  // 드래그하는 요소의 인덱스를 데이터로 설정
            //     console.log(e.dataTransfer)
            // });

            // elem.addEventListener('dragover', (e) => {
            //     e.preventDefault();  // 드랍을 허용하도록 기본 동작을 취소
            // });

            // elem.addEventListener('drop', (e) => {
            //     e.preventDefault();  // 기본 동작을 취소
            //     const draggedIdx = e.dataTransfer.getData('text/plain');  // 드래그 시작 시 설정한 데이터를 가져옴
            //     swapItems(draggedIdx, idx);  // 메뉴 순서 바꾸는 함수 호출
            // });
            
            elem.children[0].addEventListener("click", ()=> handleClick(elem));
        });

        return ()=>{
            folder.forEach((elem, idx) => {
                elem.children[0].removeEventListener("click",()=> handleClick(elem));
            });
        }

        
    }, [folderRef,menuList, dataLoading,isFolder,handleClick]);

    const swapItems = (draggedIdx, droppedIdx) => {
        const tempMenuList = [...menuList];
        const tempItem = tempMenuList[draggedIdx];
        tempMenuList[draggedIdx] = tempMenuList[droppedIdx];
        tempMenuList[droppedIdx] = tempItem;
        setMenuList(tempMenuList);
    };
    return <section>
        <div className="container">
            <div className="content title_box">
                <h2>메뉴 관리</h2>
            </div>
            <MenuStyle>
            <div className="content-space table-type lefts" ref={containerRef}>
                {dataLoading ? (
                    <>
                    <div className="main-title">
                        <button type="button">?</button>
                    </div>
                    <div className="content">
                        <ul className="folder_list" ref={folderRef}>
                            <li className="all" data-type="folder"><span><FcOpenedFolder />MENU</span>
                                <ul>
                                    {menuList?.map((elem, idx) => (
                                        <li key={idx} data-idx={elem.menu_idx} data-type="folder" data-order={elem.menu_order}>
                                            <span><FcFolder />{elem.menu_name}</span>
                                            <ul>
                                                {elem.subMenu.map((e, i) => (
                                                    <li data-group={elem.menu_idx} key={i} data-type="file" data-order={e.menu_order} data-idx={e.menu_idx}><span><BsFileText />{e.menu_name}</span></li>
                                                ))}
                                                <li data-group={elem.menu_idx} className="more" data-depth="2"><span><BsFilePlusFill />추가하기</span></li>
                                            </ul>
                                        </li>
                                    ))}
                                    <li className="more" data-depth="1" ><span><BsFilePlusFill />추가하기</span></li>
                                </ul>
                            </li>
                        </ul>
                        
                    </div>
                    </>
                ) : (
                    <ul ref={folderRef}>
                        
                    </ul>
                )}
            </div>
            <div className="content-space table-type rights">
                <div className="information-box">
                    <div className="information_content" ref={infoRef}>
                        {table}
                    </div>
                </div>
            </div>
            </MenuStyle>
        </div>
    </section>
}



