import Cookies from "js-cookie";
import CryptoJS from 'crypto-js';
import { fromUrlSafeBase64 } from "../../../config/base64";

// JSON 데이터를 쿠키에 저장하는 함수
export const setCookie = (name, data) => {
    Cookies.set(name, JSON.stringify(data), { expires: 3650 });
}

export const addCookie = (name, data) => {
    Cookies.set(name, data);
}
export const getCookieKey = (name) => {
    const secretKey = 'auth';
    const cookieData = Cookies.get(name);

    if(cookieData){

        const base= fromUrlSafeBase64(cookieData);
        const bytes = CryptoJS.AES.decrypt(base, secretKey);
        const cookie = bytes.toString(CryptoJS.enc.Utf8);
        return cookie;
    } else {
        return null;
    }
}

// 쿠키에서 JSON 데이터를 불러오는 함수
export const getCookie = (name)=> {
    if(name){
        const secretKey = 'auth';
        const cookieData = Cookies.get("SESSION_SEC");
        
        if (cookieData) {
            const json = JSON.parse(cookieData)[name];
            const base= fromUrlSafeBase64(json);
            const bytes = CryptoJS.AES.decrypt(base, secretKey);
            const cookie = bytes.toString(CryptoJS.enc.Utf8);
            return cookie;
        }
        return null;
    } else {
        const cookieData = Cookies.get("SESSION_SEC");
        if (cookieData) {
            return JSON.parse(cookieData);
        }
        return null;
    }
}

// 쿠키에서 사이트정보의 JSON 데이터를 불러오는 함수
export const getCookieSite = (name)=> {
    if(name){
        const secretKey = 'auth';
        const cookieData = Cookies.get("SEC_KEY");
        
        if (cookieData) {
            const json = JSON.parse(cookieData)[name];
            const base= fromUrlSafeBase64(json);
            const bytes = CryptoJS.AES.decrypt(base, secretKey);
            const cookie = bytes.toString(CryptoJS.enc.Utf8);
            return cookie;
        }
        return null;
    } else {
        const cookieData = Cookies.get("SEC_KEY");
        if (cookieData) {
            return JSON.parse(cookieData);
        }
        return null;
    }
}

export const removeCookie= target => Cookies.remove(target);