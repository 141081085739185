import React, { useEffect, useRef, useState } from "react";
import { slideToggle } from "./Slide";
import { SearchStyle } from "./Search/SearchStyle";

function SelectDefault(props){
    const children = props.children;
    const selectRef = useRef(null);
    const onChange = {
        value : "",
        text : ""
    }
    const [selected, setSelected] = useState(onChange);
    
    useEffect(()=>{
        const str = selectRef.current.innerText;

        const onChange = {
            value : selectRef.current.getAttribute("value"),
            text : selectRef.current.innerText
        }
        setSelected(onChange);
        if(props.onSelect){
            props.onSelect(onChange);
        }
        
    }, [selectRef]);

    
    const selectHandle = (event)=>{
        const elem = event.currentTarget.parentElement;
        elem.classList.toggle("open");
        slideToggle(elem.querySelector("ul"), 500);
    }

    const selectChange = (event)=>{
        const elem = event.currentTarget.parentElement;
        const container = elem.parentElement;
        const onChange = {
            value : event.target.getAttribute("value"),
            text : event.target.innerText
        }
        setSelected(onChange);

        container.classList.toggle("open");
        slideToggle(elem, 500);

        if(props.onChange)props.onChange(onChange);
        if(props.onSelect) props.onSelect(onChange);
    }



    //if(children && props.value) return <div className="">데이터 타입을 알려주세요</div>;
   
    return <div className={"select-custom "+props.className} >
        <div className="select-custom-selected" onClick={selectHandle}>{selected.text}
            <span className="arrow"><i></i></span>
        </div>
        <ul>
        {React.Children.map(children, (elem, idx) => !elem.props.selected ? (
            <li key={idx} value={elem.props.value} onClick={selectChange}>{elem.props.children}</li>
        ) : (
            <li key={idx} className="selected" ref={selectRef} onClick={selectChange} value={elem.props.value}>{elem.props.children}</li>
        ))}
        </ul>
    </div>
}

function SelectButton(props){
    const children = props.children;
    const selectRef = useRef(null);
    const onChange = {
        value : "",
        text : ""
    }
    const [selected, setSelected] = useState(onChange);
    
    useEffect(()=>{
        const str = selectRef.current.innerText;

        const onChange = {
            value : selectRef.current.getAttribute("value"),
            text : selectRef.current.innerText
        }
        setSelected(onChange);
        if(props.onSelect){
            props.onSelect(onChange);
        }
        
    }, [selectRef]);
    

    const selectChange = (event)=>{
        const elem = event.currentTarget.parentElement;
        const container = elem.parentElement.querySelectorAll("li");
        const onChange = {
            value : event.target.getAttribute("value"),
            text : event.target.innerText
        }
        setSelected(onChange);
        container.forEach((el) => el.classList.remove("selected"))
        event.currentTarget.classList.add("selected");

        if(props.onChange)props.onChange(onChange);
        if(props.onSelect) props.onSelect(onChange);
    }

    return <div className={"select-custom-box "+props.className} >
            
            <ul>
            {React.Children.map(children, (elem, idx) => !elem.props.selected ? (
                <li key={idx} value={elem.props.value} onClick={selectChange}>{elem.props.children}</li>
            ) : (
                <li key={idx} className="selected" ref={selectRef} onClick={selectChange} value={elem.props.value}>{elem.props.children}</li>
            ))}
            </ul>
    </div>
}




export default function Select(props){
    return <SearchStyle>
        {props.type != "button" ? (
            <SelectDefault {...props} />
        ) : (
            <SelectButton {...props} />
        )}
    </SearchStyle>
}