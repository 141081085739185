import { useEffect, useState } from "react"
import { postAPI, putAPI } from "../../../data/data";
import { getCookie, getCookieKey, getCookieSite } from "../../common/cookies/cookiesAuth";
import styled from "styled-components";

const InquiryListStyle = styled.section`
    table{
        width:100%;
        border:1px solid #ddd;
        text-align:center;
    }
    table thead {background-color:#f9f9f9;}
    table thead th {/* background-color:#343434; */ /* padding: 7px 10px; */ /* color: #fff; */}
    table th,td {text-align:center; border-right:1px solid #ddd;}
    table thead{background:#f9f9f9}
    table thead th {background: #eaf2f5; color: #6286A8;font-weight: bold;}
    table th{padding:10px 20px 7px; text-align:center}
    table th+th{/* border-right:none */}
    table tbody td:first-child {}
    table td{padding:10px 20px 7px; text-align:center}
    table td.bd{border-right-width:1px;background-color:#f7fcfc}
    table tr.view td {
        opacity : 0.5;
    }
`

export const InquiryList = ()=> {

    const site_id = getCookieSite("site_id");
    const [list, setList] = useState([]);
    useEffect(()=>{
        const getMsg = async ()=>{

            const res = await postAPI("/msg/getList",null, {site_id});
            setList(res.data);


            
        }
        getMsg();
    }, [])
    return <InquiryListStyle>
        <div className="container">
            <div className="content title_box">
                <h2>문의내용</h2>
            </div>

            <div className="content">
                <table>
                    <thead>
                        <tr>
                            <th>이름</th>
                            <th>제목</th>
                            <th>연락처</th>
                            <th>내용</th>
                            <th>문의날짜</th>
                        </tr>
                    </thead>
                    <tbody>
                        {list.map((item, idx)=>{
                            const date = new Date(item.regdate);
                            const year = date.getFullYear();
                            const month = date.getMonth() + 1;
                            const day = date.getDate();
                            const hours = date.getHours();
                            const minutes = date.getMinutes();
                            const seconds = date.getSeconds();
                
                            const regdate = `${year}년 ${month}월 ${day}일`;
                        return (
                            
                            <tr className={(item.view === 1) ? "" : "view"} key={idx}>
                                
                                <td>{item.username}</td>
                                <td>{item.msg_title}</td>
                                <td>{item.phone}</td>
                                <td><Btn view={item.view} msg_idx={item.msg_idx} content={item.msg_content} /></td>
                                <td>{regdate}</td>
                            </tr>
                        )})}
                    </tbody>
                </table>
            </div>
        </div>
        
    </InquiryListStyle>
}

const Btn = ({view, msg_idx, content})=>{
    const [isView, setIsView] = useState(false);

    const clickHandle = async ()=>{
        if(view === 2) return setIsView(true);
        const res = await putAPI("/msg/putMsg", {msg_idx});

        if(res.data === "SUCCESS"){
            setIsView(true);
        }
    }
    return <>
        {isView ? (
            content
        ) : (

            <button type="button" onClick={clickHandle}>내용보기</button>
        )}
    </>
}