import { s3Save } from "../../../data/data";
import { FOLDER } from "../../../data/port";
import { v4 as uuidv4 } from 'uuid';
import { getCookieSite } from "../cookies/cookiesAuth";


export default class MyUploadAdapter {
    constructor(loader, newPath) {
        this.loader = loader;
        this.newPath = newPath;
        this.imageElement = null;
    }

    upload() {
        return this.loader.file
            .then(file => new Promise((resolve, reject) => {
                const fileExtension = file.name.split('.').pop();
                const randomFilename = `${Date.now()}_${uuidv4()}.${fileExtension}`;
                const path = `${getCookieSite("folder")}/${this.newPath}/${randomFilename}`;

                s3Save(file, path)
                    .then((uploadedUrl) => {
                        let img = document.createElement('img');
                        img.src = uploadedUrl;

                        // Save the image element
                        this.imageElement = img;

                        resolve({ default: uploadedUrl }); // 업로드된 URL을 설정합니다.
                    })
                    .catch(reject);
            }));
    }

    getImageElement() {
        return this.imageElement;
    }
}
