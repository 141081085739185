import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { useRef, useState } from "react";
import MyUploadAdapter from "../MyUpload";
import { v4 as uuidv4 } from 'uuid';
import { FOLDER } from "../../../../data/port";
import { s3Save } from "../../../../data/data";
import { getCookieSite } from "../../cookies/cookiesAuth";



export const TextEditor = ({data,refs, newPath})=> {
    const editorRef = useRef(null);
    const edrtorConfiguration = {
		toolbar: {
			items: [
				'heading',
				'|',
				'fontSize',
				'fontFamily',
				'|',
				'fontColor',
				'fontBackgroundColor',
				'|',
				'bold',
				'italic',
				'underline',
				'strikethrough',
				'|',
				'alignment',
				'|',
				'numberedList',
				'bulletedList',
				'|',
				'outdent',
				'indent',
				'|',
				'todoList',
				'link',
				'blockQuote',
				'imageUpload',
				'mediaEmbed',
				'|',
				'undo',
				'redo',
				'insertTable'
			]
		},
		language: 'ko',
		image: {
			toolbar: [
				'imageTextAlternative',
				'toggleImageCaption',
				'imageStyle:inline',
				'imageStyle:block',
				'imageStyle:side',
				'linkImage'
			]
		},
        fontSize: {
            options: [
                10,
                12,
                14,
                'default',
                18,
                20,
                26,
                28,
                30
            ]
        },
		table: {
			contentToolbar: [
				'tableColumn',
				'tableRow',
				'mergeTableCells',
				'tableCellProperties',
				'tableProperties'
			]
		}
    };

    return <>
    <CKEditor
            editor={Editor}
            config={edrtorConfiguration}
            data={data}
            onReady={editor => {
                editorRef.current = editor;
				refs.current = editor;
                editor.ui.getEditableElement().parentElement.insertBefore(
                    editor.ui.view.toolbar.element,
                    editor.ui.getEditableElement()
				);

                
                editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
                    const myFile = new MyUploadAdapter(loader, newPath);
                    const uploadPromise = myFile.upload();
            
                    uploadPromise.then(() => {
                        myFile.loader.file.then(file => {
                            if (file) {
                                const fileExtension = file.name.split('.').pop();
                                const randomFilename = `${Date.now()}_${uuidv4()}.${fileExtension}`;
                                const path = `${getCookieSite("folder")}/${newPath}/${randomFilename}`;
								
								myFile.getImageElement().setAttribute("src", myFile.default);
                                //setUploadedFiles((prev) => [...prev , {file: file, newFileName : path, dom: myFile.getImageElement()}]);
                            }
                        })
                    });
            
                    return myFile;
                };


            }}
            
        />
    </>

}