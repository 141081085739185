import styled from "styled-components";


export const PagingStyle = styled.div`
@-webkit-keyframes main {
    0% { -webkit-transform: scaleX(0); }
    100% { -webkit-transform: scaleX(1); }
}

@-webkit-keyframes edge {
    0%, 50% { -webkit-transform: scaleY(0); }
    100% { -webkit-transform: scaleY(1); }
}

@-webkit-keyframes backRibbon {
    0%, 75% { -webkit-transform: scaleX(0); }
    100% { -webkit-transform: scaleX(1); }
}

/* moz */
@-moz-keyframes main {
    0% { -moz-transform: scaleX(0); }
    100% { -moz-transform: scaleX(1); }
}

@-moz-keyframes edge {
    0%, 50% { -moz-transform: scaleY(0); }
    100% { -moz-transform: scaleY(1); }
}

@-moz-keyframes backRibbon {
    0%, 75% { -moz-transform: scaleX(0); }
    100% { -moz-transform: scaleX(1); }
}

/* standard */
@keyframes main {
    0% { transform: scaleX(0); }
    100% { transform: scaleX(1); }
}

@keyframes edge {
    0%, 50% { transform: scaleY(0); }
    100% { transform: scaleY(1); }
}

@keyframes backRibbon {
    0%, 75% { transform: scaleX(0); }
    100% { transform: scaleX(1); }
}

#wrap {padding:0 10px;}
#wrap h3 { display: inline-block; margin-right: 50px; font:bold 16pt/130% "맑은 고딕","Malgun gothic","Segoe UI",AppleGothic,Arial,Sans-serif;color:#c3122f;}
#wrap section > h3, footer h3 {padding-left:10px; border-left:10px solid #c3122f;  }
#wrap h4 { position: relative; margin:0 0 5px 0;font:bold 11pt/130% "맑은 고딕","Malgun gothic","Segoe UI",AppleGothic,Arial,Sans-serif;color:#347bfc;}
#wrap h4 .progress { display: inline-block; position: absolute; right: 0;bottom: -3px; color: #c3122f;}
#wrap h4.h4_first {margin-top:20px;}
#wrap .wid {display: inline-block;width:150px}

.total { float: right; color: #666; font-size: 13px;}
p.total span { font-weight: bold; color: #fff; font-size: 14px;}
.btn-guide {display: none; margin-left: 50px;}

.btn-guide a {
    padding: 5px 20px;
    text-decoration: none;
    position: relative;
    display: inline-block;
    color: #6e6e6e;
    box-shadow: inset 0 0 0 0.125em #7b9ede;
    background-color: transparent;
    transition: color 0.2s ease-out, box-shadow 0.2s ease-out, background-color 0.2s ease-out, font-size 0.2s;
}
.btn-guide a:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 2px;
    left: 2px;
    border-bottom: 1px solid #95acdc;
    border-right:1px solid #95acdc;
}
.btn-guide a:hover {
    color: #7f7f7f;
    font-weight: bold;
    background-color: #e2e2e2;
    box-shadow: inset 0 0 0 0.125em transparent;
}
.btn-guide a:hover:after {
    border-color: #d0cfcf;
}
.btn-guide a:active {
    transform: translate(2px, 2px);
}
.btn-guide a:active:after {
    transform: translate(-2px, -2px);
}

table {width:100%;border-spacing:0;border-collapse:collapse;border:1px solid #ddd; color:#555;}
caption {padding:0 0 5px 0;text-align:right;}
thead {background-color:#f9f9f9;}
thead th {/* background-color:#343434; */ /* padding: 7px 10px; */ /* color: #fff; */}
th,td {/* padding:3px 4px; *//* border-top:1px solid #ddd; *//* border-right:1px solid #ddd; */}
td a {color:#125fae;text-decoration:none;}
.dowork td a:hover {color:#444;text-decoration:underline;}
tbody tr.line { border-top: 2px solid #666;}
.dowork tbody tr:hover td{background:#eee; font-weight: bold;}
tbody .odd td {background:#fafafa;}
.tac {text-align:center;}

.guide-wrap { margin-top: 20px;}

/* guide-tab */
.guide-tap ul { *zomm:1; border-bottom: 1px solid #000;}
.guide-tap ul:after { content: ""; display: block; overflow: hidden; clear: both;}
.guide-tap ul li { float: left; position: relative; height: 31px; }
.guide-tap ul li a { display: block; padding: 0 12px; background-color: #f5f5f5; color: #7a7a7a; border:solid #c6c9ca; border-width: 1px 1px 0 0; text-align: center; line-height:30px; cursor: pointer;}
.guide-tap ul li.active a { position: relative;border:1px solid #000;border-bottom: 1px solid #fff; margin-left:-1px;background-color: #fff; color: #000; font-weight: bold;}
.guide-tap ul li:first-child a { border-left: 1px solid #c6c9ca;}
.guide-tap ul li.active:first-child a { border-left: 1px solid #000; margin-left: 0;}

.guide-contents { margin-top: 50px;}
.guide-type { margin-bottom: 40px;}
.guide_tit { margin-bottom: 10px; color: #fff; padding: 4px 15px 8px; background: #3d3d3d; border-left: 7px solid #ff2b40; }
.sub-tit { margin-bottom: 15px; color: #152e45; font-size: 14px;}
.sub-tit span { padding: 5px 15px 5px 5px; border-top: 3px solid #347BFC; font-weight: bold; }
.guide_tb {width:100%}
.guide_tb thead{background:#f9f9f9}
.guide_tb thead th {background: #eaf2f5; color: #6286A8;font-weight: bold;}
.guide_tb th{padding:10px 20px 7px; text-align:center}
.guide_tb th+th{/* border-right:none */}
.guide_tb tbody td:first-child {}
.guide_tb td{padding:10px 20px 7px; text-align:left}
.guide_tb td.bd{border-right-width:1px;background-color:#f7fcfc}
.guide_tb em{ font-weight:bold;font-size:18px}
.description {position:relative; background: #eeefef; border:3px solid #c3c4c5; margin:0 0 2em 0; padding:1em; overflow:auto; }
.description ul { padding-left: 20px; }
.description ul li { list-style-type: disc; line-height: 20px; font-size: 11px; color: #333;}

hr.line { border-bottom: 2px solid #565553; margin-bottom: 30px;}
#contents { margin: 0 10px 10px;}
.tal {text-align: left !important; }
.state-wrap {
    overflow: hidden;
    padding: 20px 10px 20px 0;
    background-color: rgba(255,255,255,.3);
    margin: 5px 0;
}
.state-group strong { display: inline-block;border-left:5px solid #3d3d3d; padding-left: 5px;}
.state-group {float: left;}
.state {font-size: .95em;text-align: center;}
.state-group .state + .state {margin-left: 10px;}
.state span {padding: 3px 2px 1px 2px; display: inline-block;width: 18px;height: 18px; font-size: 12px;color: #fff;}
.state .undecided {border-radius:20px;background-color: #0abab5;}
.state .edit {border-radius:20px;background-color: #82cf54;}
.state .delete {border-radius:20px;background-color: #fc2d59;}
.state .complete {border-radius:20px;background-color: #4169e1;}
.total {float: right;font-size: 15px;color: #fff;}
.total span {font-size: 13px;}
.per {background:#525252;display: inline-block;padding: 2px 10px 4px; margin-right: 20px;}
.status { background-color: #5f6061; display: inline-block;padding: 2px 10px 4px;}
.quest {background: #f9f1d0;}
.comp {background: #f2f2f2;}
table {width: 100%;table-layout: fixed;border-collapse:collapse;}
.working-list thead th {background-color: #333;font-size: 12px;border:1px solid #ebebeb;padding: 5px;color: #fff;}
.working-list tbody tr:hover { background-color: #ddfaf8 !important;}
.working-list tbody td {text-align: center;border: 1px solid #cacaca;padding: 5px 5px 5px 10px;line-height: 1.5;font-size: 12px; color: #333;}
.working-list tbody tr .depth { color: #5a5a5a;}
.working-list tbody tr.del td { background: #fff !important;text-decoration: line-through;}
.working-list tbody .linetitle td {font-weight: bold;text-align: left; padding: 0; border:0;}
.working-list tbody .linetitle:hover { background-color: transparent !important;}
.working-list tbody .linetitle:first-child td .sub-tit { margin-top: 3px;}
.working-list tbody .linetitle td .sub-tit { margin:5px 0 0;background-color: #c5c5c5; text-shadow: 1px 1px 1px #fff;padding: 5px 5px 5px 15px;/*color: #ffae00;*/ color: #000; border-bottom: 1px solid #878787;border-top: 1px solid #ccc;
}
.working-list select { height: 24px; padding-bottom: 2px;line-height: 24px; border: 1px solid #ccc; font-size: 12px;}
hr { margin: 20px 0;border:0;border-bottom: solid 1px #ddd;}
footer { *zoom:1;}
footer:after {display:block;content:"";clear:both;}
footer .author {float:right; }
footer h3 {float:left; color: #c3122f; }
footer dl { overflow: hidden; padding:3px 10px 0 30px; margin-left: 50px; color: #444; }
dt {float:left;font-weight:bold;color:#888;}
dd { margin-left: 45px;}
.img_swipe {
    font-size: 0;
    line-height: 0;
    background:url(http://cfile28.uf.tistory.com/image/25340F4958A4ED7B04E913) no-repeat;
    display: inline-block;
    width: 6px;
    height: 10px;
    margin-left: 5px;
    text-indent: -9999px;
    overflow: hidden;
}
.bounce_ani {
    -webkit-animation:horizontalBounce 5s linear infinite both;
    animation:horizontalBounce 5s linear infinite both;
}
.result select {
    border: 0;
    background-color:#525252;
    font-size: 14px;
    color:#fff;
    text-align:center;
    cursor: pointer;
}
@-webkit-keyframes horizontalBounce {
    0%, 10%, 30%, 50%, 70%, 90%, 100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    
    20%, 40%, 60%, 80% {
        -webkit-transform: translateX(10px);
        transform: translateX(10px);
    }
}
`;



export const AlpakaPagingButtonStyle = styled.div`
    .btn_container{
        display:flex;
        justify-content: center;
        gap: 25px;
        padding: 50px 15px;
        align-items: center;
    }
    .btn_container ul{
        display:flex;
        justify-content: center;
        gap: 10px;
        align-items: center;
    }
    .btn_container ul li{
        cursor: pointer;
        width: 30px;
        height: 30px;
        background-color: transparent;
        border: 1px solid var(--main-color);
        border-radius: 50px;
        display:flex;
        justify-content: center;
        align-items: center;
        color:#333;
        font-size: 12px;
    }
    .btn_container ul li.active{
        background-color: var(--main-color);
        color:#fff;
    }
    .btn_container ul li a{
        display:flex;
        justify-content: center;
        align-items: center;
        color:#fff;
        font-size: 12px;
    }
    .prev, .next{
        width: 30px;
        height: 30px;
        
        position: relative;
        background-color: var(--main-color);
        border-radius: 50px;
        opacity: 0.4;
    }
    .prev.active, .next.active{
        opacity: 1;
        cursor: pointer;
    }
    .prev::after,
    .prev::before{
        content: "";
        position: absolute;
        display:block;
        width: 1px;
        height:10px;
        background-color: #fff;
        left: 50%;
    }
    .prev::after{
        bottom: 7px;
        transform: rotate(-45deg);
    }
    .prev::before{
        top: 6px;
        transform: rotate(45deg);
    }


    .next::after,
    .next::before{
        content: "";
        position: absolute;
        display:block;
        width: 1px;
        height:10px;
        background-color: #fff;
        left: 50%;
    }
    .next::after{
        bottom: 7px;
        transform: rotate(45deg);
    }
    .next::before{
        top: 6px;
        transform: rotate(-45deg);
    }
`;