import {AiFillEye, AiFillEyeInvisible} from "react-icons/ai";
import '../../resoureces/css/login/login.css';
import { useContext, useEffect, useRef, useState } from "react";
import { login } from "../../data/auth/auth";
import { useNavigate } from "react-router-dom";
import { AlertContext } from "../../App";
import Cookies from "js-cookie";
import { removeCookie } from "../common/cookies/cookiesAuth";




export default function Login({ onLogin }){
    const [eye, setEye] = useState(<AiFillEye />);
    const navigate = useNavigate();
    const [adminId, setAdminId] = useState('');
    const [adminPw, setAdminPw] = useState('');
    const eyeRef = useRef(null);
    const { setIsAlertVisible, setAlertMsg } = useContext(AlertContext);


    const load = ()=>{
        removeCookie("SESSION_SEC");
    }

    const eyeHandler = ()=>{
        if(eyeRef.current.type === "password"){
            setEye(<AiFillEyeInvisible />);
            eyeRef.current.type = "text";
        } else {
            setEye(<AiFillEye />);
            eyeRef.current.type = "password";
        }
    }

    const handleSubmit = async (e)=>{
        e.preventDefault();
        
        const result = await login({adminId, adminPw});

        if (result === "success") {
            if (onLogin) {
                onLogin();
            }
        } else {
            setAlertMsg(result.msg);
            setIsAlertVisible(true);
            
        }
    }

    return <div className="wrapper">
        <section id="section" className="login-section">
            <div className="section_table">
                <div className="section_inner">
                    <div className="section_cover clearfix">
                        <div className="right_content">
                            <div className="right_content_inner">
                                <div className="right_content_cover">
                                    <form id="admin_login" name="f" method="POST" onSubmit={handleSubmit}>
                                        
                                        <h3>
                                            ADMIN <span className="green">LOGIN</span>
                                        </h3>
                                        <div className="id">
                                            <span className="label">아이디</span> <input type="text" value={adminId} onChange={(e) => setAdminId(e.target.value)} name="adminId" autoComplete="off" />
                                        </div>
                                        <div className="pas">
                                            <span className="label">비밀번호</span> 
                                            <input type="password" ref={eyeRef} id="password" value={adminPw} onChange={(e) => setAdminPw(e.target.value)} autoComplete="current-password" name="adminPw" /> 
                                            <span className="eye" onClick={eyeHandler}>
                                                {eye}
                                            </span>
                                        </div>
                                        <input type="submit" value="로그인" />
                                    </form>
                                    <div className="member">
                                        <ul>
                                            <li>회원가입</li>
                                            <li>ID/PW찾기</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
</div>
}