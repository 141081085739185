import { useEffect, useRef, useState } from "react";
import { getAbility } from "../../data/ability/ability";
import animationData from "../../resoureces/lottie/animation_lnqez9cn.json";
import lottie from "lottie-web";

export default function NotFount(){
    const container = useRef(null);
    useEffect(() => {
        lottie.loadAnimation({
          container: container.current,
          renderer: 'svg',
          loop: true,
          autoplay: true,
          animationData: animationData
        });
    }, [container]);



    return <section>
        <div className="container">
            
            <div className="content">
                <h3>페이지 준비중입니다.</h3>

                <div className="main_content">

                    <div className="notFound" ref={container}></div>
                    <p style={{textAlign: "center"}}>페이지 준비중입니다...</p>
                </div>
            </div>
        </div>
    </section> 
    
}