import { useEffect, useRef, useState } from "react";
import CryptoJS from 'crypto-js';
import Cookies from "js-cookie";
import { fromUrlSafeBase64 } from "../../../config/base64";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { getCookie, getCookieKey, getCookieSite, removeCookie } from "../cookies/cookiesAuth";


const HeaderStyle = styled.div`
    .util li{
        
        position: relative;
        padding: 10px 0;
        overflow: hidden;
    }
    .util li:hover{
        overflow: visible;
    }
    .util li a{
        display:block;
    }
    .util li span{
        cursor:pointer;
        text-decoration: underline;
    }
    .util ul ul{
        position: absolute;
        right: 0;
        background-color: #fff;
        width: 150px;
        border-radius: 8px;
        height: 0;
        transition: height 0.3s;
        overflow: hidden;
        flex-wrap: wrap;
        text-align:center;
        justify-content: center;
        box-shadow: 0px 0px 5px #333;
        gap: 10px !important;
        align-items: center;
    }
    .util ul ul li{
        width: 100%;
    }
    .util li:hover ul{
        height: 150px;
    }
    .util li li{
        cursor:pointer;
    }
`



export default function Header({isAside, setAside}){
    const name = getCookie("SEC_NAME");
    const site_id = getCookieSite("site_id");

    const menuRef = useRef(null);
    const dimRef = useRef(null);
    useEffect(()=>{
        const menu = menuRef.current;
        

        menu.addEventListener("click", ()=>{
            menu.classList.toggle("active");
            dimRef.current.classList.toggle("active");
        });


        dimRef.current.addEventListener("click", ()=>{
            menu.classList.remove("active");
            dimRef.current.classList.remove("active");
        });

        
        window.addEventListener("resize", (e)=>{
            setTimeout(()=>{
                const w = window.innerWidth;
                if(menu.classList.contains("active") && w < 950){
                    menu.classList.remove("active");
                    dimRef.current.classList.remove("active");
                }
            }, 1);
        });
        
    }, [menuRef, dimRef]);

    const logout = (e)=>{
        e.preventDefault();

        removeCookie("SESSION_SEC");
        removeCookie("SEC_KEY");
        alert("로그아웃 되었습니다.");
        window.location.href="/";
    }

    const chg = (e)=>{
        e.preventDefault();

        removeCookie("SEC_KEY");
        window.location.href="/";
    }

    const toggleAside = () => {
        setAside(prev => !prev); // 이전 상태를 반전시킴
    };
        
    return <HeaderStyle>
        <div className="dim" ref={dimRef} onClick={()=>setAside(false)}></div>
        <header id="header">
        <div className="header_inner">
            <div className={`menu_bar ${(isAside)? "active" : ""}`} ref={menuRef} onClick={toggleAside}>
                <span>메뉴바</span>
            </div>
            <div className="admin_logo">
                <h1><a href="/">ADMIN</a></h1>
            </div>

            <div className="util">
                <ul>
                    <li><span>{name} 님</span>
                        <ul>
                            <li><Link to={site_id} target="_blank">홈페이지 바로가기</Link></li>
                            <li onClick={chg}>사이트 전환</li>
                            <li onClick={logout}>로그아웃</li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </header>
    </HeaderStyle>
    
}