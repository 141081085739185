import { useEffect, useState } from "react";
import { postAPI } from "../../../data/data";
import styled from "styled-components";
import { Link } from "react-router-dom";
import CryptoJS from 'crypto-js';
import { fromUrlSafeBase64, toUrlSafeBase64 } from "../../../config/base64";
import Cookies from "js-cookie";
import { getCookie, getCookieKey, getCookieSite } from "../../common/cookies/cookiesAuth";
const PageStyle = styled.div`
    .content{
        display:flex;
        justify-content:space-between;
        align-items:center;
    }
    .page_btn{
        margin-left: auto;
        padding: 0;
    }
    .page_btn a{
        padding: 10px 20px;
        display:block;
        color:#fff;
    }
`;

const PageList = ({setContent})=>{
    const [menuList, setMenuList] = useState(null);
    const [typeList, setTypeList] = useState(null);
    const secretKey = 'menu_idx'; // 비밀 키

    const site_id = getCookieSite("site_id");
    useEffect(()=>{
        const fetchData = async () => {
            
            const data = await postAPI("/menu/list",null, {site_id});
            //console.log(data);
            setMenuList(data.data);
        };
        fetchData();
    },[]);
    return <>
        {menuList?.map((item, idx)=>(
            item.menu_type_idx != 4 && 
            <div key={idx} className="content">
                <span>{item.menu_name}</span>
                {/*onClick={()=> setContent(<AlpakaEditor menu_idx={item.menu} />)}*/}
                <button type="button" className="page_btn" >
                    <Link to={`/homepage/pageEdit?editNumber=${toUrlSafeBase64(CryptoJS.AES.encrypt(item.menu_idx.toString(), secretKey).toString())}`}>
                        페이지 설정
                    </Link>
                </button>
            </div>
        ))}
    </>
}

export const PageEdit = ()=>{
    const [content, setContent] =useState(null);

    useEffect(()=>{
        setContent(<PageList setContent={setContent} />);
    }, [])
    return <PageStyle>
        <section>
            <div className="container">
                <div className="content title_box">
                    <h2>페이지 관리</h2>
                </div>
                {content}
            </div>
        </section>
    </PageStyle>
}