


export default function menuArr(){
    const obj = [
        {
            title : "DASHBORD",
            link : "/",
            sub : []
        },
        {
            title : "문의 현황",
            link : "/orders",
            sub : [
            ]
        },
        {
            title : "홈페이지 관리",
            link : "",
            sub : [
                
                {
                    title : "메뉴 관리",
                    link : "/homepage/menu",
                },
                {
                    title : "키비쥬얼 관리",
                    link : "/homepage/keyvisual",
                },
                {
                    title : "메인페이지 관리",
                    link : "/homepage/mainpage",
                },
                {
                    title : "페이지 관리",
                    link : "/homepage/pageList",
                }
            ]
        },
        {
            title : "게시판 관리",
            link : "",
            sub : [
                {
                    title : "게시판 관리",
                    link : "/notice/boardList"
                },
                {
                    title : "게시글 작성",
                    link : "/notice/board"
                },
            ]
        },
        {
            title : "상품 관리",
            link : "",
            sub : [
                {
                    title : "상품 관리",
                    link : "/product/productList"
                }
            ]
        },
        
    ];

    return obj;
}