import axios from "axios";
import { API, V1 } from "../port";
import Cookies from "js-cookie";
import { authMemo } from "../../App";
import CryptoJS from 'crypto-js';
import { toUrlSafeBase64 } from "../../config/base64";
import { getCookie, getCookieKey, getCookieSite, setCookie } from "../../templates/common/cookies/cookiesAuth";
export const loginState = ()=>{

    const auth = getCookie();

    
    if(auth != null){
        return true;
    }else {
        return false;
    }
}

export const SuccessType = ()=>{
    
    const sey_key = getCookieSite("site_id");

    if(sey_key != null){
        return false;
    }else {
        return true;
    }
}


export const login = async ({adminId, adminPw}) => {
    try {
        const response = await axios.post(API+V1+"/auth/login", {
            admin_id: adminId,
            admin_pw: adminPw,
        }, {
            headers: {
                
                'Content-Type': 'application/json'
            }
        });
        const secretKey = 'auth';

        const accessToken = response.data.access;
        const access64 = toUrlSafeBase64(CryptoJS.AES.encrypt(accessToken, secretKey).toString());


        const admin_id = response.data.admin_id;
        const admin_idx = response.data.admin_idx;
        const admin_name = response.data.admin_name;
        const role = response.data.auth_role;

        const data = {
            accessToken : toUrlSafeBase64(CryptoJS.AES.encrypt(accessToken, secretKey).toString()),
            SEC_ID : toUrlSafeBase64(CryptoJS.AES.encrypt(admin_id, secretKey).toString()),
            SEC_IDX : toUrlSafeBase64(CryptoJS.AES.encrypt(admin_idx.toString(), secretKey).toString()),
            SEC_NAME : toUrlSafeBase64(CryptoJS.AES.encrypt(admin_name, secretKey).toString()),
            SEC_SEQ_KEY : toUrlSafeBase64(CryptoJS.AES.encrypt(role, secretKey).toString())
        }

        setCookie("SESSION_SEC", data);
        
        return "success";
      } catch (error) {
        const errors = {
            msg : error.response.data,
            code : error.response.status
        }

        return errors;
      }
}