import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { fromUrlSafeBase64 } from "../../../config/base64";
import { TextEditor } from "../../common/component/Editor/TextEditor";
import { DatePic } from "../../common/component/DatePic";
import { numberToKorean } from "number-to-korean";
import { ImageCrop } from "../../common/component/ImageCrop";
import { postAPI, putAPI, s3Save } from "../../../data/data";
import styled from "styled-components";
import CryptoJS from 'crypto-js';
import { getCookieKey, getCookieSite } from "../../common/cookies/cookiesAuth";
import { FOLDER, S3 } from "../../../data/port";
const ProductStyle = styled.div`
.ck-rounded-corners .ck.ck-editor__editable:not(.ck-editor__nested-editable), 
.ck.ck-editor__editable.ck-rounded-corners:not(.ck-editor__nested-editable){
    min-height: 500px;
    border: 1px solid #ccc;
}
padding-bottom: 100px;
input[type=text]{
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 15px 5px;
}
.content{
    transition: height 0.3s;
}
.content.hidden{
    opacity: 0.5;
    cursor: no-drop;
}
.content.hidden .content_box{
    display:none;
}
p.sub_title{
    display:block;
    position:relative;
    padding-left: 25px;
    margin-bottom: 15px;
}
p.sub_title::before{
    position: absolute;
    display:block;
    content:"";
    left: 0;
    top: 0;
    width: 6px;
    height: 100%;
    border-radius: 20px;
    background-color: var(--main-color);
}
select{
    width: 100%;
    padding: 15px 5px;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-top: 20px;
}
.main_con{
    display: flex;
    justify-content: start;
    gap: 20px;
    flex-direction: column;
    align-items: center;
}
.main_image{
    display:flex;
    justify-content:center;
}
.main_content{
    
}
.main_content ul{
    display:flex;
    justify-content:center;
    gap: 10px;
    margin-top: 20px;
}
.main_content ul li{
    width: calc((100% - 40px) /5);
    text-align:center;
}
.main_content ul li img{
    width: 100%;
    border: 1px solid #ccc;
}
.formData{
    padding: 50px 0;
}
.formData table{
    width: 100%;
    text-align:left;
}
.formData table thead th{
    text-align:center;
}
.formData table th span{
    font-size: 10;
    color: #ccc;
    font-weight: 300;
}
.formData table td p{
    text-align:center;
    width:100%;
    padding: 15px 5px;
    text-decoration: line-through;
}
.formData table tbody th{
    padding-top: 40px;
    padding-bottom: 10px; 
}
input[type="text"]{
    border: 0;
}
.react-datepicker__input-container input[type="text"]{
    
    text-align:center;
}
input[type="text"]:focus{
    outline: none;
}
.react-datepicker-wrapper{
    text-align:center;
}

.date-picker{
    display: flex;
    align-items: center;
    width: 350px;
    border: 2px solid #333;
    border-radius: 3px;
}
.file_content{
    display:flex;
    gap: 20px;
    justify-content:center;
}
.editor{
    padding: 50px 0;
}
.btn{
    padding: 50px 0;
    text-align:center;
}
`;



export const ProductEdit = ()=>{
    const inputFile = useRef(null);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const secretKey = 'product_idx_key';
    const editNumber = fromUrlSafeBase64(queryParams.get('p'));

    const productBytes = CryptoJS.AES.decrypt(editNumber, secretKey);
    const product_idx = Number(productBytes.toString(CryptoJS.enc.Utf8));

    const editorRef = useRef(null);
    const containerRef =useRef(null);
    const [main_img, setMain_img] = useState("");
    const [selectedImage, setSelectedImage] = useState("/images/img/img210.png");
    const [dis, setDis] = useState({display: "none"});
    const [imgArray, setImgArray] = useState([]);

    const site_id = getCookieSite("site_id");
    const [isRemove, setIsRemove] = useState(true);
    const [isLoad, setIsLoad] = useState(false);
    const [isDate, setIsDate] = useState(false);
    const [values, setValues] = useState(
        {
            menu_idx : 0,
            name : "",
            amount : 0,
            sale : 0,
            comment : "",
            orders : 0,
            start_date : null,
            end_date : null,
            product_idx : 0,
            content: "",
            regdate: null,
            shortText : "",
            origin : "",
            state : 0
        }
    );
    const [date, setDate] = useState({
        start_date : null,
        end_date : null,
        isDate : false
    });

    const onButtonClick = () => {
        if(imgArray.length >= 5){
            
            return alert("최대 5개까지 등록이 가능합니다.");
        }
        inputFile.current.click();
    };
    
    const onFileChange = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener("load", () => setMain_img(reader.result));
            reader.readAsDataURL(event.target.files[0]);
            setDis({display:"flex"});
        }
    };

    const onCropComplete = (croppedImage) => {
        setSelectedImage(croppedImage.cropSrc);
        setImgArray((prev) => {
            
            return prev = [...prev, croppedImage]
        });
        setDis({display:"none"});
    };

    const removeImage = ()=>{
        setImgArray((prev)=>{
            setIsRemove(false);
            return prev.filter((item) => item.cropSrc !== selectedImage);
        });
    }

    useEffect(()=>{
        
        if(imgArray.length === 0){
            setSelectedImage("/images/img/img210.png");
        } else {
            if(imgArray[imgArray.length - 1].data === null){
                setSelectedImage(imgArray[0].cropSrc);
            } else {
                setSelectedImage(imgArray[imgArray.length - 1].cropSrc);
            }
        }

        setIsRemove(true);
    }, [imgArray])


    useEffect(()=>{
        

        const fetchData = async () => {
            const data = await postAPI("/product/getProduct",null, {product_idx, site_id});
            const main = data.data.imgList.filter((img)=> img.img_main === 1);
            if(data.data.start_date == null || data.data.end_date == null){
                
                setValues((prev) => prev ={...prev, start_date : null, end_date : null});
                setIsDate(false);
            } else {
                setValues((prev) => prev ={...prev, start_date : new Date(data.data.start_date), end_date : new Date(data.data.end_date)})
                setIsDate(true);
            }
            setValues((prev)=> prev = {
                ...prev,
                menu_idx : data.data.menu_idx,
                name : data.data.title,
                amount : data.data.amount,
                sale : data.data.event,
                comment : data.data.comment,
                orders : data.data.orders,
                product_idx : data.data.product_idx,
                content: data.data.content,
                regdate: data.data.regdate,
                shortText: (data.data.short_text != null) ? data.data.short_text : "",
                origin : (data.data.origin != null) ? data.data.origin : "",
                state : data.data.state
            })
            data?.data.imgList.sort((a, b) => b.img_main - a.img_main).map((item, idx) =>{
                const main_img = `${S3}/${getCookieSite("folder")}/product/${item.img_url}`;
                const obj = {
                    cropSrc : main_img,
                    data : null,
                    filename : item.img_url,
                    path : null
                }
                if(idx === 0){

                    setSelectedImage(main_img);
                }
                setImgArray((prev) => [...prev, obj]);

            })
            setIsLoad(true);
        };
        fetchData();
    }, []);




    const onSubmit = async ()=>{
        
        if(imgArray.length == 0){
            return alert("대표사진을 최소 한장 이상 올려주세요");
            
        } 
        if(values.name == "" || values.name == null){

            return alert("상품명을 등록해주세요");
            
        }
        

        const imgList = [];

        for(let item of imgArray){

            try{

                let img_main = 0;
                (item.cropSrc == selectedImage) ? img_main = 1 : img_main = 0;
                
                imgList.push(
                    {
                        img_url : item.filename,
                        img_main
                    }
                )
                if(item.data == null) continue;
                const save = await s3Save(item.data, `${getCookieSite("folder")}/product/${item.filename}`);
            }catch(ex){
                alert("에러가 발생했습니다.");
            }
            


        }


        const req = {
            product_idx : product_idx,
            menu_idx : values.menu_idx,
            title : values.name,
            amount : values.amount,
            event : values.sale,
            content: editorRef.current.getData(),
            start_date : values.start_date,
            end_date : values.end_date,
            comment : values.comment,
            orders : values.orders,
            short_text : values.shortText,
            origin : values.origin,
            state : values.state,
            imgList : imgList
        }
        const fetchData = async () => {
            const data = await putAPI("/product/putProduct", req);
            if(data.data === "SUCCESS"){
                alert("상품 수정이 완료되었습니다.");
                window.history.back();
            }
        };
        fetchData();
    }


    
    return <ProductStyle> 
    <section>
        <div className="container" ref={containerRef}>
            <div className="content" style={{display: "flex", alignItems:"center"}}>
                <h3>상품 수정</h3>
                {values.state === 0 && (
                    <span style={{marginLeft: "auto", color: "var(--main-color)", fontSize: "22px"}}>정상</span>
                )}
                {values.state === 1 && (
                    <span style={{marginLeft: "auto", color: "var(--sub-color)", fontSize: "22px"}}>품절</span>
                )}
                {values.state === 2 && (
                    <span style={{marginLeft: "auto", color: "red", fontSize: "22px"}}>숨김</span>
                )}
                
            </div>

            <div className="content">
                <h4>STEP.01</h4>
                <p>대표사진 등록 <span>* 최소 1개 ~ 최대 5개</span></p>

                <div className="main_con content_box">
                    <div className="main_content">
                        <div className="main_image">
                            <img src={selectedImage} alt="대표이미지" />
                            <ImageCrop displays={dis} image={main_img} onCropComplete={onCropComplete} />
                        </div>
                        <ul>
                            {isRemove &&imgArray?.map((item, idx)=>(
                                <li key={idx}>
                                    <label htmlFor={`img${idx}`}>
                                        <img src={item.cropSrc}></img>
                                        <div className="inputBox">
                                            <input type="radio" 
                                                value={idx} 
                                                id={`img${idx}`} 
                                                name="img" 
                                                onClick={(e)=>setSelectedImage(item.cropSrc)} 
                                                defaultChecked={(item.cropSrc == selectedImage) ? true : false} 
                                            />
                                            
                                        </div>
                                    </label>
                                </li>
                            ))}
                        </ul>
                    </div>
                    
                    <div className="file_content">
                        <div className="files">
                            <button type="button" onClick={onButtonClick}>사진찾기</button>
                            <input type="file" accept="image/*" style={{display: "none"}} ref={inputFile} onChange={onFileChange} />
                        </div>
                        <button type="button" onClick={removeImage} style={{"backgroundColor" : "red"}}>선택 사진 삭제</button>
                    </div>
                </div>
            </div>

            <div className="content">
                <h4>STEP.02</h4>
                <p>상품 정보 입력</p>
                <div className="formData content_box">
                    <table>
                        <thead>
                            <tr>
                                <th colSpan={2}>상품정보 입력!</th>
                            </tr>
                        </thead>
                        <tbody>
                            
                            <tr>
                                <th>상품명</th>
                            </tr>
                            <tr>
                                <td><input type="text" value={values.name} onChange={(e)=> setValues((prev) => prev = {...prev, name: e.target.value})} /></td>
                            </tr>
                            <tr>
                                <th>상품 순서</th>
                            </tr>
                            <tr>
                                <td>
                                    <input type="text"
                                        value={values.orders === '' ? 0 : parseInt(values.orders)}
                                        onChange={(e)=> {
                                            const re = /^[0-9\b]+$/;
                                            if(e.target.value > 10000000000) return;
                                            if (e.target.value === '' || re.test(e.target.value)) {
                                                let inputVal = e.target.value === '' ? '0' : e.target.value;
                                                
                                                setValues((prev) => ({...prev, orders: inputVal}));
                                            }
                                        }}
                                        onFocus={(e) => e.target.select()}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>상품 가격<span> * 단위 : 원, 숫자만 입력 </span></th>
                            </tr>
                            <tr>
                                <td>
                                    <input type="text"
                                        value={values.amount === '' ? 0 : parseInt(values.amount)}
                                        onChange={(e)=> {
                                            const re = /^[0-9\b]+$/;
                                            if(e.target.value > 10000000000) return;
                                            if (e.target.value === '' || re.test(e.target.value)) {
                                                let inputVal = e.target.value === '' ? '0' : e.target.value;
                                                
                                                setValues((prev) => ({...prev, amount: inputVal}));
                                            }
                                        }}
                                        onFocus={(e) => e.target.select()}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span style={{fontSize: "22px", color: "#333"}}>{numberToKorean(Number(values.amount.toString().replace(/,/g, ''))) + '원'}</span>
                                </td>
                            </tr>
                            <tr>
                                <th>할인률 <span>* 0일경우 할인금액 없음</span></th>
                            </tr>
                            <tr>
                                <td><input type="text"
                                    value={values.sale === '' ? 0 : parseInt(values.sale)}
                                    style={{width: "50px"}}
                                    onChange={(e)=> {
                                        const re = /^[0-9\b]+$/;
                                        if(e.target.value > 100) return;
                                        if (e.target.value === '' || re.test(e.target.value)) {
                                            let inputVal = e.target.value === '' ? '0' : e.target.value;
                                            
                                            setValues((prev) => ({...prev, sale: inputVal}));
                                        }
                                    }}
                                    onFocus={(e) => e.target.select()}
                                /><span>%</span></td>
                            </tr>
                            <tr>
                                <td>
                                    <span style={{fontSize: "22px", color: "#333"}}>
                                        할인후 가격 :
                                        {" " + parseInt(values.amount).toLocaleString() + "원 - " + values.sale + "% = " + (values.amount - (values.amount * values.sale / 100)).toLocaleString() + "원" }
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <th>원산지</th>
                            </tr>
                            <tr>
                                <td>
                                    <input type="text" placeholder="ex) 국내산" value={values.origin} onChange={(e)=> setValues((prev) => prev = {...prev, origin: e.target.value})} />
                                </td>
                            </tr>
                            <tr>
                                <th>기간 <span>*판매날짜가 없을경우 무제한</span></th>
                            </tr>
                            <tr>
                                <td>
                                    {isLoad && (

                                        <DatePic isRegdate={isDate} dates={
                                            {
                                                startDate : values.start_date, 
                                                endDate : values.end_date, 
                                            }
                                        } onChange={(dates)=> {setValues((prev)=> prev = {...prev, start_date : dates.startDate, end_date : dates.endDate})}} />
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <th>대체 문구 <span>*상품가격이 0이거나 기간이 미정일경우 대체할 문구</span></th>
                            </tr>
                            <tr>
                                <td><input type="text" placeholder="ex) 준비중.." value={values.comment} onChange={(e)=> setValues((prev) => prev = {...prev, comment: e.target.value})} /></td>
                            </tr>

                            <tr>
                                <th>짧은설명 <span>* 상품을 짧게 설명해주세요</span></th>
                            </tr>
                            <tr>
                                <td><input type="text" placeholder="상품설명을 짧게 작성해주세요!" value={values.shortText} onChange={(e)=> setValues((prev) => prev = {...prev, shortText: e.target.value})} /></td>
                            </tr>

                            <tr>
                                <th>상품상태</th>
                            </tr>
                            <tr>
                                <td>
                                    <label>
                                        <input type="radio" onChange={(e)=> setValues((prev) => prev = {...prev, state: e.target.value})} checked={(values.state == 0) ? true : false} name="state" value={0}/><span>정상</span>
                                    </label>
                                    <label>
                                        <input type="radio" onChange={(e)=> setValues((prev) => prev = {...prev, state: e.target.value})} checked={(values.state == 1) ? true : false} name="state" value={1}/><span>품절</span>
                                    </label>
                                    <label>
                                        <input type="radio" onChange={(e)=> setValues((prev) => prev = {...prev, state: e.target.value})} checked={(values.state == 2) ? true : false} name="state" value={2}/><span>숨김</span>
                                    </label>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div className="content">
                <h4>STEP.03</h4>
                <p>상세내용 입력</p>
                <div className="editor content_box">
                    <TextEditor data={values.content} newPath={"product"} refs={editorRef} />
                
                    <div className="btn">
                        <button type="button" onClick={onSubmit}>상품수정</button>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </ProductStyle>
}