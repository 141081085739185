import { useEffect, useRef } from "react";


export const slideUp = (target, duration=500, elem) => {


  target.style.transitionProperty = 'height, margin, padding';
  target.style.transitionDuration = duration + 'ms';
  target.style.boxSizing = 'border-box';
  target.style.height = target.offsetHeight + 'px';


  target.style.overflow = 'hidden';

  return new Promise((resolve) => {
    window.setTimeout(()=>{
      target.style.height = 0;
    
      target.style.paddingTop = 0;
      target.style.paddingBottom = 0;
      target.style.marginTop = 0;
      target.style.marginBottom = 0;
      if(elem){
        elem.classList.remove("active");
      }
    }, 1);

    window.setTimeout( () => {
      target.style.display = 'none';
      target.style.removeProperty('height');
      target.style.removeProperty('padding-top');
      target.style.removeProperty('padding-bottom');
      target.style.removeProperty('margin-top');
      target.style.removeProperty('margin-bottom');
      target.style.removeProperty('overflow');
      target.style.removeProperty('transition-duration');
      target.style.removeProperty('transition-property');
      
    }, duration);
  }, 1);
}

export const slideDown = (target, duration=500, elem)=>{
  target.style.removeProperty('display');
  let display = window.getComputedStyle(target).display;
    

    if (display === 'none') display = 'block';
    target.style.display = display;
    let height = target.offsetHeight;
    target.style.overflow = 'hidden';
    target.style.height = 0;
    target.style.paddingTop = 0;
    target.style.paddingBottom = 0;
    target.style.marginTop = 0;
    target.style.marginBottom = 0;
 


    target.style.boxSizing = 'border-box';
    target.style.transitionProperty = "height, margin, padding";
    target.style.transitionDuration = duration + 'ms';
    return new Promise((resolve) => {
      window.setTimeout(()=>{
        if(elem){
          elem.classList.add("active");
        }
        target.style.height = height + 'px';
      },1)
      target.style.removeProperty('padding-top');
      target.style.removeProperty('padding-bottom');
      target.style.removeProperty('margin-top');
      target.style.removeProperty('margin-bottom');
      window.setTimeout( () => {
        target.style.removeProperty('height');
        target.style.removeProperty('overflow');
        target.style.removeProperty('transition-duration');
        target.style.removeProperty('transition-property');
      }, duration);
    }, 1);
    
}


export const slideToggle = (target, duration = 500, elem) =>{
  
  if (window.getComputedStyle(target).display === 'none') {
    return slideDown(target, duration, elem);
  } else {
    return new Promise((resolve) => { 
      slideUp(target, duration, elem).then(resolve);
    });
  }
}