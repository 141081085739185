import { useContext, useEffect, useState } from "react"
import { AlertContext } from "../../../App"






export default function Alert({view, msg, handle}){
    const { setIsAlertVisible, setAlertMsg, setAlertHandle } = useContext(AlertContext);
    const handler = ()=>{
        setIsAlertVisible(false);
        setAlertMsg("");
    }
    const body = document.querySelector("body");
    useEffect(()=>{
        const blockKeyboardEvent = (event) => {
            if(view) event.preventDefault();
        }

        window.addEventListener("keydown", blockKeyboardEvent);
        (view) ? body.classList.add("fixed") : body.classList.remove("fixed");
        
        return () => {
            window.removeEventListener("keydown", blockKeyboardEvent);
        }
    }, [view])
    

    
    

    return <div className="alert_container" style={{ display: view ? "flex" : "none" }}>
        <div className="alert_cover">
            <p>{msg}</p>
            <button type="button" onClick={()=> (handle == null) ? handler() : handle()}>확인</button>
        </div>
    </div>
}