
import { useEffect, useRef, useState } from "react";
import ReactCrop from 'react-image-crop'
import "react-image-crop/dist/ReactCrop.css";
import styled from "styled-components";
import { v4 as uuidv4 } from 'uuid';
import { FOLDER } from "../../../data/port";
import { getCookieSite } from "../cookies/cookiesAuth";


const ImageCropStyle = styled.div`
    width:100%;
    height: 100vh;
    left: 0;
    top: 0;
    background-color: rgba(0,0,0,0.3);
    position:fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    
    .inner{
        max-width: 800px;
        width: 800px;
        height: 500px;
        background-color : #fff;
        border-radius: 5px;
        overflow: auto;
    }
    .img_inner{
        background-color: rgba(0,0,0,0.3);
        
    }
    img{
        width: 100%;
    }
    svg circle {
        fill: red;
        opacity: 0.5;
    }
    h3{
        text-align:center;
        padding: 25px 0;
    }
    .react-crop_img_inner{
        margin: 0 auto;
        max-width: 500px;
        border: 1px solid #333;
        position:relative;
    }
    .saveBtn{
        display:none;
    }
    
`
export const ImageCrop = ({ image, onCropComplete, displays})=>{
    const [crop, setCrop] = useState({
        unit: 'px', // Can be 'px' or '%'
        x: 25,
        y: 25,
        width: 210,
        height: 210,
        aspect: 1 / 1,
        fixed: true  // 이 부분 추가
    });
    const [newData, setNewData]= useState({});
      
    const imgRef = useRef(null);
    const fileUrl = useRef();  // fileUrl을 ref로 관리
    const btn = useRef(null);
    useEffect(()=>{
        setNewData(null);
        btn.current.style.display="none";
    }, [onCropComplete, btn])
    
    const onCropChange = (crop) => {
        setCrop(crop);
    };
    function dataURLtoBlob(dataurl) {
        var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], {type:mime});
    }
    function blobToFile(blob, fileName) {
        return new File([blob], fileName, { type: 'image/jpeg', lastModified: Date.now() });
    }

    const onCropCompleteChange = async (crop,percentCrop) => {
        if (imgRef.current && crop) {
            
            const randomFilename = `${Date.now()}_${uuidv4()}`;
            const croppedImageDataURL = await getCroppedImg(imgRef.current, crop, `${randomFilename}.jpeg`);
            const croppedImageBlob = dataURLtoBlob(croppedImageDataURL);
            const croppedImageFile = blobToFile(croppedImageBlob, `${randomFilename}.jpeg`);

            const path = `${getCookieSite("folder")}/${randomFilename}.jpeg`;
            setNewData({cropSrc : croppedImageDataURL, data : croppedImageFile, filename : croppedImageFile.name, path : path});
        }
    };
    const dragStart = ()=>{
        btn.current.style.display="none";
    }
    const dragEnd = ()=>{
        btn.current.style.display="block";
    }

    const saveCrop = ()=> {
        onCropComplete(newData);
    }

    const getCroppedImg = (image, crop, fileName) => {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
        );

        return new Promise((resolve, reject) => {
        canvas.toBlob(blob => {
            if (!blob) {
            console.error('Canvas is empty');
            return;
            }
            blob.name = fileName;
            window.URL.revokeObjectURL(fileUrl.current);  // fileUrl을 ref로 관리
            fileUrl.current = window.URL.createObjectURL(blob);
            resolve(canvas.toDataURL('image/jpeg'));
        }, 'image/jpeg');
        });
    };

    return (
        <ImageCropStyle style={displays}>
            <div className="inner">
                <h3>사진자르기</h3>
                <div className="react-crop_img_inner">

                    <ReactCrop
                    crop={crop}
                    ruleOfThirds
                    onComplete={onCropCompleteChange}
                    onChange={onCropChange}
                    onDragEnd={dragEnd}
                    onDragStart={dragStart}
                    aspect={1 / 1}
                    minWidth = {210}
                    minHeight = {210}
                    maxWidth = {210}
                    maxHeight = {210}
                    
                    >
                        <div className="img_inner">
                            <img ref={imgRef} src={image} />
                        </div>
                    </ReactCrop>
                <button ref={btn} style={{
                    position: "absolute",
                    left: crop.x + "px",
                    top: crop.y + -39 +"px"
                }} type="button" className="saveBtn" onClick={()=>saveCrop()}>저장</button>
                </div>
            </div>
        </ImageCropStyle>
    );
}