import axios from "axios";
import { getAccessToken } from "./auth/token";
import { API, V1 } from "./port";
import animationData from '../resoureces/lottie/loading/loading.json';
import Lottie from "lottie-web";
import AWS from "aws-sdk";


export const putAPI = async (target, obj) => {
    const accessToken = getAccessToken();

    try {

        const response = await axios.put(API+V1+target, obj, {
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            }
        });
        const res = {...response, isResult: true};

        return res;
      } catch (error) {
        const errors = {
            msg : error.response.data,
            code : error.response.status
        }

        return errors;
    }
}


export const postAPI = async (target, elem, obj) => {
    const accessToken = getAccessToken();
    let animation;
    try {

        const parent = document.createElement('div');
        if(elem){
            parent.className = 'loading-container on';
            animation = Lottie.loadAnimation({
                container: parent,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: animationData,
                rendererSettings: {
                    className: 'loading-content',
                }
            });
            elem.appendChild(parent);
        }

        const response = await axios.post(API+V1+target, obj, {
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            }
        });
        const res = {...response, isResult: true};
        if(elem){
            setTimeout(()=>{
                parent.classList.remove("on");
                animation.destroy();
            }, 1000)
        }

        return res;
      } catch (error) {
        const errors = {
            msg : error.response.data,
            code : error.response.status
        }

        return errors;
    }
}


export const s3Save = (imageFile, filePath)=> {
    return new Promise((resolve, reject) => {


        AWS.config.update({
            region: process.env.REACT_APP_REGION,
            accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
            secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY_ID,
        });
        
        const upload = new AWS.S3.ManagedUpload({
            params: {
                ACL: 'public-read',
                Bucket: 'alpaka-s3-bucket',
                Key: filePath,
                Body: imageFile,
            }
        });

        upload.promise()
        .then((data)=>{
            resolve(data.Location); // 업로드된 이미지의 URL을 반환합니다.
        }).catch((error) => {
            reject(error); // 에러가 발생하면 에러 메시지를 반환합니다.
        });
    });
}
