import { useLocation } from "react-router-dom";
import { fromUrlSafeBase64 } from "../../../config/base64";
import CryptoJS from 'crypto-js';
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ImageCrop } from "../../common/component/ImageCrop";
import { FOLDER } from "../../../data/port";
import { v4 as uuidv4 } from 'uuid';
import { DatePic } from "../../common/component/DatePic";
import { numberToKorean } from "number-to-korean";
import { getCookie, getCookieKey, getCookieSite } from "../../common/cookies/cookiesAuth";
import { postAPI, putAPI, s3Save } from '../../../data/data';
import { slideDown, slideUp } from "../../common/component/Slide";
import { TextEditor } from "../../common/component/Editor/TextEditor";
/*
상품을 등록하는 영역입니다.
*/

const ProductStyle = styled.div`
.ck-rounded-corners .ck.ck-editor__editable:not(.ck-editor__nested-editable), 
.ck.ck-editor__editable.ck-rounded-corners:not(.ck-editor__nested-editable){
    min-height: 500px;
    border: 1px solid #ccc;
}
padding-bottom: 100px;
input[type=text]{
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 15px 5px;
}
.content{
    transition: height 0.3s;
}
.content.hidden{
    opacity: 0.5;
    cursor: no-drop;
}
.content.hidden .content_box{
    display:none;
}
p.sub_title{
    display:block;
    position:relative;
    padding-left: 25px;
    margin-bottom: 15px;
}
p.sub_title::before{
    position: absolute;
    display:block;
    content:"";
    left: 0;
    top: 0;
    width: 6px;
    height: 100%;
    border-radius: 20px;
    background-color: var(--main-color);
}
select{
    width: 100%;
    padding: 15px 5px;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-top: 20px;
}
.main_con{
    display: flex;
    justify-content: start;
    gap: 20px;
    flex-direction: column;
    align-items: center;
}
.main_image{
    display:flex;
    justify-content:center;
}
.main_content{
    
}
.main_content ul{
    display:flex;
    justify-content:center;
    gap: 10px;
    margin-top: 20px;
}
.main_content ul li{
    width: calc((100% - 40px) /5);
    text-align:center;
}
.main_content ul li img{
    width: 100%;
    border: 1px solid #ccc;
}
.formData{
    padding: 50px 0;
}
.formData table{
    width: 100%;
    text-align:left;
}
.formData table thead th{
    text-align:center;
}
.formData table th span{
    font-size: 10;
    color: #ccc;
    font-weight: 300;
}
.formData table td p{
    text-align:center;
    width:100%;
    padding: 15px 5px;
    text-decoration: line-through;
}
.formData table tbody th{
    padding-top: 40px;
    padding-bottom: 10px; 
}
input[type="text"]{
    border: 0;
}
.react-datepicker__input-container input[type="text"]{
    
    text-align:center;
}
input[type="text"]:focus{
    outline: none;
}
.react-datepicker-wrapper{
    text-align:center;
}

.date-picker{
    display: flex;
    align-items: center;
    width: 350px;
    border: 2px solid #333;
    border-radius: 3px;
}
.file_content{
    display:flex;
    gap: 20px;
    justify-content:center;
}
.editor{
    padding: 50px 0;
}
.btn{
    padding: 50px 0;
    text-align:center;
}
`;



export const Product = ()=>{
    const inputFile = useRef(null);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const secretKey = 'menu_idx';
    const editNumber = (queryParams.get('p')) ? fromUrlSafeBase64(queryParams.get('p')) : 0;
    const bytes = CryptoJS.AES.decrypt(editNumber, secretKey);
    const n_dx = Number(bytes.toString(CryptoJS.enc.Utf8));
    const [menu_idx, setMenu_idx] = useState(n_dx);
    const editorRef = useRef(null);
    const containerRef =useRef(null);
    const [main_img, setMain_img] = useState("");
    const [uploadedFiles,setUploadedFiles] = useState([]);
    const [selectedImage, setSelectedImage] = useState("/images/img/img210.png");
    const [dis, setDis] = useState({display: "none"});
    const [imgArray, setImgArray] = useState([]);
    const [menuList, setMenuList]= useState(null);
    const site_id = getCookieSite("site_id");
    const [isRemove, setIsRemove] = useState(true);
    const [slide, setSlide] = useState([]);
    const [isSlide, setIsSlide] = useState(false);
    const [values, setValues] = useState(
        {
            name : "",
            amount : 0,
            sale : 0,
            comment : "",
            orders : 0,
            start_date: null,
            end_date: null,
            shortText : "",
            state: 0,
            origin : ""
        }
    );
    
    const [date, setDate] = useState({
        startDate : null,
        endDate : null,
        isDate : false
    });

    const onButtonClick = () => {
        if(imgArray.length >= 5){
            
            return alert("최대 5개까지 등록이 가능합니다.");
        }
        inputFile.current.click();
    };
    
    const onFileChange = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener("load", () => setMain_img(reader.result));
            reader.readAsDataURL(event.target.files[0]);
            setDis({display:"flex"});
        }
    };

    const onCropComplete = (croppedImage) => {
        setSelectedImage(croppedImage.cropSrc);
        setImgArray((prev) => {
            
            return prev = [...prev, croppedImage]
        });
        setDis({display:"none"});
    };

    const removeImage = ()=>{
        setImgArray((prev)=>{
            setIsRemove(false);
            return prev.filter((item) => item.cropSrc !== selectedImage);
        });
    }

    useEffect(()=>{
        if(imgArray.length === 0){
            setSelectedImage("/images/img/img210.png");
        } else {
            setSelectedImage(imgArray[imgArray.length - 1].cropSrc);
        }

        setIsRemove(true);
    }, [imgArray])


    useEffect(()=>{
        const content = containerRef.current.querySelectorAll(".content_toggle");
        setSlide((prev)=> 
                prev = []
        );
        content.forEach((elem, idx)=> {
            setSlide((prev)=> 
                prev = [...prev, {elem, isView : false}]
            );
        })

        

        const fetchData = async () => {
            const data = await postAPI("/menu/list",null, {site_id});
            setMenuList(data.data);
        };
        fetchData();

        setIsSlide(true);
    },[containerRef, isSlide]);

    
    useEffect(()=>{
        if(slide.length == 0) return;
        slide.map((item) => {
            if(item.isView && item.elem.classList.contains("hidden")) {
                item.elem.classList.remove("hidden");
                slideDown(item.elem)
            }
        })
    }, [slide]);

    useEffect(()=>{
        /*if(slide.length != 0) return;*/
        if(!isSlide) return;
        
        if(menu_idx != 0 && !slide[0].isView) {
            setSlide(prev => prev.map((item, index) => 
                index === 0 ? {...item, isView: true} : item
            ))
        }
        if(imgArray.length != 0 && !slide[1].isView) {
            setSlide(prev => prev.map((item, index) => 
                index === 1 ? {...item, isView: true} : item
            ))
        } else if(imgArray.length == 0){
            setSlide(prev => prev.map((item, index) => 
                index === 1 ? {...item, isView: false} : item
            ))
        }
        
        if(values.name.length != 0 && !slide[2].isView) {
            setSlide(prev => prev.map((item, index) => 
            index === 2 ? {...item, isView: true} : item
            ))
        } else if(values.name.length == 0){
            setSlide(prev => prev.map((item, index) => 
                index === 2 ? {...item, isView: false} : item
            ))
        }
    }, [isSlide, menu_idx, imgArray, values])



    const onSubmit = ()=>{
        
        if(menu_idx == 0) {
            alert("카테고리를 선택해주세요");
            
            return window.scrollTo({
                top: 0, // 페이지의 맨 위로 이동
                behavior: 'smooth' // 부드럽게 스크롤
            });
        }
        if(!slide[1].isView){
            alert("대표사진을 최소 한장 이상 올려주세요");
            const top = slide[0].elem.offsetTop - 50;
            return window.scrollTo({
                top: top, // 페이지의 맨 위로 이동
                behavior: 'smooth' // 부드럽게 스크롤
            });
        } 
        if(!slide[2].isView){

            alert("상품명을 등록해주세요");
            const top = slide[1].elem.offsetTop - 50;
            return window.scrollTo({
                top: top, // 페이지의 맨 위로 이동
                behavior: 'smooth' // 부드럽게 스크롤
            });
        }
        

        const imgList = [];

        imgArray.map((item, idx)=> {
            let img_main = 0;
            (item.cropSrc == selectedImage) ? img_main = 1 : img_main = 0;


            imgList.push(
                {
                    img_url : item.filename,
                    img_main
                }
            )
            
            s3Save(item.data, `${getCookieSite("folder")}/product/${item.filename}`);
        });
        const req = {
            title : values.name,
            menu_idx : menu_idx,
            amount : values.amount,
            event : values.sale,
            content: editorRef.current.getData(),
            start_date : date.startDate,
            end_date : date.endDate,
            comment : values.comment,
            orders : values.orders,
            short_text : values.shortText,
            state : values.state,
            origin : values.origin,
            imgList : imgList
        }
        const fetchData = async () => {
            const data = await postAPI("/product/setProduct",null, req);
            if(data.data === "SUCCESS"){
                alert("상품 등록 완료되었습니다.");
                window.location.reload();
            }
        };
        fetchData();
    }


    
    
    return <ProductStyle> 
    <section>
        <div className="container" ref={containerRef}>
            <div className="content">
                <h3>상품 등록</h3>
                <select value={menu_idx} onChange={(e)=>setMenu_idx(e.target.value)}>
                    <option value={0}>상품카테고리</option>
                    {menuList?.map((item, idx)=>(
                        item.menu_type_idx == 3 && 
                        <option key={idx} value={item.menu_idx} >{item.menu_name}</option>
                    ))}
                </select>
            </div>

            <div className="content content_toggle hidden">
                <h4>STEP.01</h4>
                <p>대표사진 등록 <span>* 최소 1개 ~ 최대 5개</span></p>

                <div className="main_con content_box">
                    <div className="main_content">
                        <div className="main_image">
                            <img src={selectedImage} alt="대표이미지" />
                            <ImageCrop displays={dis} image={main_img} onCropComplete={onCropComplete} />
                        </div>
                        <ul>
                            {isRemove &&imgArray?.map((item, idx)=>(
                                <li key={idx}>
                                    <label htmlFor={`img${idx}`}>
                                        <img src={item.cropSrc}></img>
                                        <div className="inputBox">
                                            <input type="radio" 
                                                value={idx} 
                                                id={`img${idx}`} 
                                                name="img" 
                                                onClick={(e)=>setSelectedImage(item.cropSrc)} 
                                                defaultChecked={(item.cropSrc == selectedImage) ? true : false} 
                                            />
                                            
                                        </div>
                                    </label>
                                </li>
                            ))}
                        </ul>
                    </div>
                    
                    <div className="file_content">
                        <div className="files">
                            <button type="button" onClick={onButtonClick}>사진찾기</button>
                            <input type="file" accept="image/*" style={{display: "none"}} ref={inputFile} onChange={onFileChange} />
                        </div>
                        <button type="button" onClick={removeImage} style={{"backgroundColor" : "red"}}>선택 사진 삭제</button>
                    </div>
                </div>
            </div>

            <div className="content content_toggle hidden">
                <h4>STEP.02</h4>
                <p>상품 정보 입력</p>
                <div className="formData content_box">
                    <table>
                        <thead>
                            <tr>
                                <th colSpan={2}>상품정보 입력!</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>상품명</th>
                            </tr>
                            <tr>
                                <td><input type="text" value={values.name} onChange={(e)=> setValues((prev) => prev = {...prev, name: e.target.value})} /></td>
                            </tr>
                            <tr>
                                <th>상품 순서</th>
                            </tr>
                            <tr>
                                <td>
                                    <input type="text"
                                        value={values.orders === '' ? 0 : parseInt(values.orders)}
                                        onChange={(e)=> {
                                            const re = /^[0-9\b]+$/;
                                            if(e.target.value > 10000000000) return;
                                            if (e.target.value === '' || re.test(e.target.value)) {
                                                let inputVal = e.target.value === '' ? '0' : e.target.value;
                                                
                                                setValues((prev) => ({...prev, orders: inputVal}));
                                            }
                                        }}
                                        onFocus={(e) => e.target.select()}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>상품 가격<span> * 단위 : 원, 숫자만 입력 </span></th>
                            </tr>
                            <tr>
                                <td>
                                    <input type="text"
                                        value={values.amount === '' ? 0 : parseInt(values.amount)}
                                        onChange={(e)=> {
                                            const re = /^[0-9\b]+$/;
                                            if(e.target.value > 10000000000) return;
                                            if (e.target.value === '' || re.test(e.target.value)) {
                                                let inputVal = e.target.value === '' ? '0' : e.target.value;
                                                
                                                setValues((prev) => ({...prev, amount: inputVal}));
                                            }
                                        }}
                                        onFocus={(e) => e.target.select()}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span style={{fontSize: "22px", color: "#333"}}>{numberToKorean(Number(values.amount.toString().replace(/,/g, ''))) + '원'}</span>
                                </td>
                            </tr>
                            <tr>
                                <th>할인률 <span>* 0일경우 할인금액 없음</span></th>
                            </tr>
                            <tr>
                                <td><input type="text"
                                    value={values.sale === '' ? 0 : parseInt(values.sale)}
                                    style={{width: "50px"}}
                                    onChange={(e)=> {
                                        const re = /^[0-9\b]+$/;
                                        if(e.target.value > 100) return;
                                        if (e.target.value === '' || re.test(e.target.value)) {
                                            let inputVal = e.target.value === '' ? '0' : e.target.value;
                                            
                                            setValues((prev) => ({...prev, sale: inputVal}));
                                        }
                                    }}
                                    onFocus={(e) => e.target.select()}
                                /><span>%</span></td>
                            </tr>
                            <tr>
                                <td>
                                    <span style={{fontSize: "22px", color: "#333"}}>
                                        할인후 가격 :
                                        {" " + parseInt(values.amount).toLocaleString() + "원 - " + values.sale + "% = " + (values.amount - (values.amount * values.sale / 100)).toLocaleString() + "원" }
                                    </span>
                                </td>
                            </tr>

                            <tr>
                                <th>원산지</th>
                            </tr>
                            <tr>
                                <td><input type="text" value={values.origin} onChange={(e)=> setValues((prev) => prev = {...prev, origin: e.target.value})} /></td>
                            </tr>

                            <tr>
                                <th>기간 <span>*판매날짜가 없을경우 무제한</span></th>
                            </tr>
                            <tr>
                                <td>
                                    <DatePic dates={
                                            {
                                                startDate : new Date(), 
                                                endDate : new Date(), 
                                            }
                                        } onChange={(date)=> setDate(date)} />
                                </td>
                            </tr>
                            <tr>
                                <th>대체 문구 <span>*상품가격이 0이거나 기간이 미정일경우 대체할 문구</span></th>
                            </tr>
                            <tr>
                                <td><input type="text" placeholder="ex) 준비중.." value={values.comment} onChange={(e)=> setValues((prev) => prev = {...prev, comment: e.target.value})} /></td>
                            </tr>

                            <tr>
                                <th>짧은설명 <span>* 상품을 짧게 설명해주세요</span></th>
                            </tr>
                            <tr>
                                <td><input type="text" placeholder="상품설명을 짧게 작성해주세요!" value={values.shortText} onChange={(e)=> setValues((prev) => prev = {...prev, shortText: e.target.value})} ></input></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div className="content content_toggle hidden">
                <h4>STEP.03</h4>
                <p>상세내용 입력</p>
                <div className="editor content_box">
                    <TextEditor newPath={"product"} refs={editorRef} />
                
                    <div className="btn">
                        <button type="button" onClick={onSubmit}>상품등록</button>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </ProductStyle>
}

