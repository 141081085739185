
import { useEffect, useState } from "react";
import { ProductStyle } from "./ProductStyle"
import { AlpakaPagingButtonStyle } from "../AlpakaPaging/PagingStyle";
import { postAPI } from "../../../../data/data";
import { FOLDER, S3 } from "../../../../data/port";
import { Link } from "react-router-dom";
import CryptoJS from 'crypto-js';
import { toUrlSafeBase64 } from "../../../../config/base64";
import { getCookieSite } from "../../cookies/cookiesAuth";




/*
*@Param item = obj
*@Param secretKey = 파라미터 비밀키
*@Param api = api Url
*@Param column = 헤더와 가져올 데이터 array
*@Param btnLimit = 버튼갯수
*@Param limit = 페이지 리스트 갯수
*@Param offset = 페이지 숫자 * 2
*@Param order = 0:desc, 1:asc
*@Param start_date = 시작 날짜
*@Param start_date = 마지막 날짜
*/
export const ProductPaging = ({search, menu_idx, viewPage = 10, btnNum = 5})=> {

    const [pageNum, setPageNum] = useState(0);

    const [max, setMax] = useState(0);

    const [productList, setPageList] = useState([]);

    const [nextMax, setNextMax] = useState(0);

    const productKey = "product_idx_key";
    const productParam = (product_idx) => {
        return toUrlSafeBase64(CryptoJS.AES.encrypt(product_idx.toString(), productKey).toString());
    }

    useEffect(()=>{
        const fetchData = async () => {
            const obj = {
                menu_idx,
                viewPage,
                pageNum,
                title : search.title,
                orders : search.order
            }
            const data = await postAPI("/product/getProductList",null, obj);
            setMax(data.data.max);
            setPageList(data.data.contentList);
            setNextMax(Math.ceil(data.data.max / btnNum));
        };
        fetchData();
        
    }, [pageNum, search]);

    


    return <ProductStyle>
        <div className="product_paging">
            <ul>
                {productList.map((item, idx)=> (

                    <li key={idx} className={`${item.event > 0 ? "sale" : ""}`}>
                        <Link to={`/product/productEdit?p=${productParam(item.product_idx)}`}>
                            <dl>
                                <dt data-title="세일중"><img src={`${S3}/${getCookieSite("folder")}/product/${item.imgList.find(img => img.img_main === 1)?.img_url}`} alt="상품" /></dt>
                                <dd>
                                    <p>{item.title}</p>
                                    <span>{parseInt(item.amount).toLocaleString()}원</span>
                                </dd>
                            </dl>
                        </Link>
                    </li>
                ))}
                
            </ul>
        </div>

        <PagingButton nextMax={nextMax} pageNum={pageNum} btnMax={btnNum} onClicked={(item)=>setPageNum(item)} max={max} />
    </ProductStyle>
}


const PagingButton = ({nextMax,pageNum,onClicked, max, btnMax})=>{
    
    const [num, setNum] = useState([]);
    const [btnNum, setBtnNum] = useState(1);
    useEffect(()=>{
        const btn = btnMax * btnNum;
        const startNum = (btnNum-1) * btnMax;
        const btnArr= [];

        

        for(let i=startNum+1; i<=btn; i++){
            btnArr.push(i);
            if(i >= max){
                break;
            }
        }
        
        setNum(btnArr);

    }, [btnNum,nextMax])

    

    return <>
        <AlpakaPagingButtonStyle>
            <div className="btn_container">
                <div className={`prev ${(btnNum <= 1) ? "" : "active"}`} onClick={()=>{
                    if(btnNum <= 1) return;

                    setBtnNum((prev)=> {
                        return prev - 1
                    });


                    onClicked(()=> {
                        return ((btnNum - 1) * btnMax) - btnMax;
                    });
                }}></div>
                <ul>
                    {num.map((item, idx) => (
                        <li className={(item === (pageNum + 1)) ? "active" : ""} key={idx} onClick={() => onClicked(item - 1)}>
                        {item}
                        </li>
                    ))}
                </ul>
                <div className={`next ${(nextMax != 0 && nextMax != btnNum) ? "active" : ""}`} onClick={()=>{
                    if(btnNum >= nextMax) return;
                    setBtnNum((prev)=> {
                        return prev + 1
                    });
                    onClicked(()=> {
                        return btnMax * btnNum;
                    });
                }}></div>
            </div>
        </AlpakaPagingButtonStyle>
    </>
}