
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './resoureces/css/common/reset.css';
import './resoureces/css/common/header.css';
import './resoureces/css/content/content.css';
import Login from "./templates/login/Login";
import { SuccessType, loginState } from './data/auth/auth';
import Home from './templates/home/Home';
import React, { useEffect, useState } from 'react';
import Alert from './templates/common/component/Alert';
import Aside from './templates/common/header/Aside';
import Header from './templates/common/header/Header';
import OrderList from './templates/content/orders/OrderList';
import Board from './templates/content/notice/Board';
import MenuInsert from './templates/content/homePage/menu';
import { PageEdit } from './templates/content/homePage/PageEdit'
import { AlpakaEditor } from './templates/common/component/AlpakaEditor';
import { BoardList } from './templates/content/notice/BoardList';
import { BoardSetting } from './templates/content/notice/BoardSetting';
import BoardEdit from './templates/content/notice/BoardEdit';
import { LoginSuccess } from './templates/login/LoginSuccess';
import { ProductList } from './templates/content/product/ProductList';
import { ProductSetting } from './templates/content/product/ProductSetting';
import { Product } from './templates/content/product/Product';
import { ProductEdit } from './templates/content/product/ProductEdit';
import { InquiryList } from './templates/content/inquiry/InquiryList';
import NotFount from './templates/error/404';

export const AlertContext = React.createContext();

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(loginState());
  const [isSuccess, setIsSuccess] = useState(SuccessType());
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const handleAlertConfirm = () => {
    return null;
  };


  const [alertHandle, setAlertHandle] = useState(handleAlertConfirm());
  const contenxt = {
    isAlertVisible,
    setIsAlertVisible,
    alertMsg,
    setAlertMsg,
    alertHandle,
    setAlertHandle
  };

  const [isAside, setAside] = useState(false);

  return (
    <>
    <AlertContext.Provider value={contenxt}>
      <Alert view={isAlertVisible} msg={alertMsg} handle={alertHandle}/>
      {isLoggedIn && !isSuccess && (
        <>
          <Header isAside={isAside} setAside={setAside} />
          <Aside isAside={isAside} setAside={setAside} />
        </>
      )}
      <Routes>
        {!isLoggedIn ? (
          <Route path="*" element={<Login onLogin={() => setIsLoggedIn(true)} />} />
          ) : (
            <>
            {isSuccess ? (
              <Route path="*" element={<LoginSuccess />} />
            ) : (
              <>
              <Route path="*" element={<NotFount />} />
              <Route path="/" element={<Home />} />
              <Route path='/notice/boardSetting' element={<BoardSetting />} />
              <Route path='/notice/boardList' element={<BoardList />} />
              <Route path="/notice/boardEdit" element={<BoardEdit />} />
              <Route path="/notice/board" element={<Board />} />
              <Route path="/homepage/menu" element={<MenuInsert />} />
              <Route path="/homepage/pageList" element={<PageEdit />} />
              <Route path="/homepage/pageEdit" element={<AlpakaEditor />} />
              <Route path="/product/productList" element={<ProductList />} />
              <Route path="/product/productSetting" element={<ProductSetting />} />
              <Route path="/product/product" element={<Product />} />
              <Route path="/product/productEdit" element={<ProductEdit />} />
              <Route path="/orders" element={<InquiryList />} />
              </>
            )}
            </>
          )}
      </Routes>
    </AlertContext.Provider>
    </>
  );
}


const MainCom = ()=> {
  return <>
    
  </>
}

export default App;


