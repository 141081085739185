import styled from "styled-components";



export const SearchStyle = styled.div`

.search-container{
    width: 100%;
    padding: 30px 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.search-container .date-picker{
    display: flex;
    align-items: center;
    width: 100%;
    border: 2px solid #333;
    border-radius: 3px;
}
.react-datepicker-wrapper{
    width: 50%;
}
.react-datepicker__input-container input[type=text]{
    border: 0;
    height: 100%;
    padding: 10px 5px;
    text-align: center;
    font-size: 18px;
    width: 100%;
}
.react-datepicker__input-container input[type=text]:focus{
    outline: none;
}

/* select box */
.select-box{
    width: 100%;
}
.select-custom .select-custom-selected{
    width: 100%;
    text-align: center;
    padding: 10px 15px;
    border: 2px solid #333;
    border-radius: 5px;
    position: relative;
    cursor: pointer;
    transition: border 0s;
    transition-delay: 0.5s;
}
.select-custom .select-custom-selected span{
    position: absolute;
    width: 26px;
    height: 100%;
    display: block;
    right: 5px;
    top: 0;
    
}
.select-custom .select-custom-selected span i{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}
.select-custom .select-custom-selected span i::before,
.select-custom .select-custom-selected span i::after{
    content: "";
    position: absolute;
    display: block;
    width: 15px;
    height: 1px;
    background-color: #333;
    transition: transform 0.5s;
}
.select-custom .select-custom-selected span i::before{
    left: 0;
    transform: rotate(45deg);
}
.select-custom .select-custom-selected span i::after{
    right: 0;
    transform: rotate(-45deg);
}

.select-custom.open .select-custom-selected span i::before{
    transform: rotate(-45deg);
}
.select-custom.open .select-custom-selected span i::after{
    transform: rotate(45deg);
}
.select-custom.open .select-custom-selected{
    
    border-bottom: none;
    border-radius: 5px 5px 0 0;
}
.select-custom ul{
    display: none;
    width: 100%;
    border: 2px solid #333;
    border-top: none;
    text-align: center;
    border-radius: 0 0 5px 5px;
}
.select-custom ul li{
    cursor: pointer;
    padding: 10px 5px;
    max-height: 300px;
    overflow: auto;
    cursor: pointer;
}
.select-custom ul li:hover{
    background-color: var(--main-color);
    color: #ffffff;
}

/* select box타입 */
.select-custom-box{
    display: inline-flex;
    padding: 5px 0;
}
.select-custom-box ul{
    display: flex;
    justify-content: start;
    border: 1px solid #333;
    border-radius: 5px;
    overflow: hidden;
}
.select-custom-box ul li{
    cursor: pointer;
    padding: 5px 10px;
    background-color: #eaeaea;
    border-right: 1px solid #333;
}
.select-custom-box ul li.selected{
    background-color: var(--main-color);
    color: #fff;
}
.select-custom-box ul li:last-child{
    border-right: none;
}
.search-button{
    width: 100%;
    padding: 10px 5px;
    border: none;
    background-color: var(--main-color);
    color: #fff;
    font-size: 22px;
    border-radius: 5px;
    cursor: pointer;
}


.input-box{
    width: 100%;
    padding: 15px 10px;
    font-size: 18px;
    border:2px solid #333;
    border-radius: 5px;
}
.input-box:focus{
    outline: none;
}






.search-is-box{
    padding: 20px 0;
    width: 60%;
}
.search-is-box:first-child{
    padding-top: 0;
}

@media only screen and (max-width: 950px) {
    .search-is-box{
        width: 100%;
    }
    .react-datepicker__input-container input[type=text],
    .input-box{
        font-size: 16px;
    }
}

@media only screen and (max-width: 500px) {
    
    .select-custom-box ul li{
        font-size: 12px;
    }
    
}

@media only screen and (max-width: 300px) {
    
    .select-custom-box ul li{
        font-size: 10px;
        padding: 5px 5px;
    }
    .react-datepicker__input-container input[type=text],
    .input-box{
        font-size: 12px;
    }
}
`