import axios from "axios";
import { getAccessToken } from "../auth/token";
import { API, V1 } from "../port";



export const userMenuList = async () => {
    const accessToken = getAccessToken();
    try {
        const response = await axios.post(API+V1+"/menu/list", {
        }, {
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            }
        });
        return response;
      } catch (error) {
        const errors = {
            msg : error.response.data,
            code : error.response.status
        }

        return errors;
    }
}