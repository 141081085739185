
export const designOriginalHTML = ()=>{


    return ``
}

const designObj =[
    {
        type : 1,
        title: "텍스트 좌측 이미지 타입",
        html : `
        <div class="sub_content">
            
            <div class="sub_content_inner">
                <div class="left">
                    <div class="text-box">
                        <p class="sub_title">이곳에 텍스트를 넣어주세요!! (텍스트는 한글파일에서 작성후 붙여넣는걸 추천드립니다.)</p>
                        <p class="sub_title_sub">
                        이곳에 텍스트를 넣어주세요!!
                        </p>
                    </div>
                </div>
                <div class="right radis">
                    <img src="/images/sub/default.png" alt="">
                </div>
            </div>
        </div>
        `,
        img: "/images/sub/list_01.png",
        style : `
            .sub_content_inner{
                padding: 0px 25px;
                display: flex;
                justify-content: space-between;
                align-items: start;
                gap: 20px;
            }
            .sub_title{
                font-size: 27px;
                color: blue;
                margin-bottom: 20px;
            }
            .sub_title_sub{
                font-size: 16px;
                font-weight: 300;
                color: #666
            }
            .left{
                width: 100%;
                position: relative;
                display: flex;
            }
            .right{
                width: 100%;
                overflow: hidden;
                position: relative;
            }
            .radis{
                border-radius: 20px;
                overflow:hidden;
            }
            img{
                100%
            }
        `
    },
    {
        type : 2,
        title: "텍스트 우측 이미지 타입",
        html : `
        <div class="sub_content">
            
            <div class="sub_content_inner">
                <div class="left radis">
                    <img src="/images/sub/default.png" alt="">
                    
                </div>
                <div class="right">
                    <div class="text-box">
                        <p class="sub_title">이곳에 텍스트를 넣어주세요!! (텍스트는 한글파일에서 작성후 붙여넣는걸 추천드립니다.)</p>
                        <p class="sub_title_sub">이곳에 텍스트를 넣어주세요!! (텍스트는 한글파일에서 작성후 붙여넣는걸 추천드립니다.)
                        </p>
                    </div>
                </div>
            </div>
        </div>
        `,
        img: "/images/sub/list_02.png",
        style : `
            .sub_content_inner{
                padding: 0px 25px;
                display: flex;
                justify-content: space-between;
                align-items: start;
                gap: 20px;
            }
            .sub_title{
                font-size: 27px;
                color: blue;
                margin-bottom: 20px;
            }
            .sub_title_sub{
                font-size: 16px;
                font-weight: 300;
                color: #666
            }
            .left{
                width: 100%;
                position: relative;
                display: flex;
            }
            .right{
                width: 100%;
                overflow: hidden;
                position: relative;
            }
            .radis{
                border-radius: 20px;
                overflow:hidden;
            }
            img{
                100%
            }
    
        `
    },
    {
        type : 3,
        title: "텍스트 우측 멀티 이미지 타입",
        html : `
        <div class="sub_content">
            <div class="sub_content_inner">
                <div class="left">
                    <div class="imge_static">
                        <img src="/images/sub/default.png" alt="">
                    </div>
                    <div class="imge_free">
                        <img src="/images/sub/default.png" alt="">
                    </div>
                </div>
                <div class="right">
                    <div class="text-box">
                        <p class="sub_title">이곳에 텍스트를 넣어주세요!! (텍스트는 한글파일에서 작성후 붙여넣는걸 추천드립니다.)</p>
                        <p class="sub_title_sub">
                        이곳에 텍스트를 넣어주세요!! (텍스트는 한글파일에서 작성후 붙여넣는걸 추천드립니다.)
                        </p>
                    </div>
                </div>
            </div>
        </div>
        `,
        img: "/images/sub/list_01.png",
        style: `
            .sub_content_inner{
                padding: 0px 25px;
                display: flex;
                justify-content: space-between;
                align-items: start;
                gap: 20px;
            }
            .sub_title{
                font-size: 27px;
                color: blue;
                margin-bottom: 20px;
            }
            .sub_title_sub{
                font-size: 16px;
                font-weight: 300;
                color: #666
            }
            .left{
                width: 100%;
                position: relative;
                display: flex;
            }
            .right{
                width: 100%;
                overflow: hidden;
                position: relative;
            }
            .radis{
                border-radius: 20px;
                overflow:hidden;
            }
            img{
                100%
            }
        
            .imge_static{
                width: 50%;
            }
            .imge_free{
                width: 50%;
                margin-left: -100px;
                margin-top: 100px;
            }
        `
    }

]
export const designList = ()=>{
    return designObj;
}
export const design = (type)=> {
    return designObj.filter(obj => obj.type === type);
}