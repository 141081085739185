import Select from "./Select"
import DatePicker from "react-datepicker";
import { ko } from 'date-fns/locale';
import "react-datepicker/dist/react-datepicker.css";
import { useEffect, useState } from "react";
import { addDays, addMonths, addYears, format } from "date-fns";
import { FaRegCalendarAlt } from "react-icons/fa";


export const DatePic = ({onChange, dates, isRegdate})=>{
    
    
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [isDate, setIsDate] = useState(false);
    const [isLoad, setIsLoad] = useState(false);
    
    const handleSelect = (data) => {
        let ch = {
            startDate : null,
            endDate : null,
            isDate: false
        }
        
        switch (data.value) {
            case '미정':
                
                setStartDate(null);
                setEndDate(null);
                setIsDate(false);
                ch = {
                    ...ch,
                    startDate : null,
                    endDate : null,
                    isDate: false
                }
                onChange(ch);
                break;
                
            
            case '당일':
                if(!isRegdate){
                    
                    setStartDate(new Date());
                    setEndDate(new Date());
                    setIsDate(true);
                    ch = {
                        ...ch,
                        startDate : new Date(),
                        endDate : new Date(),
                        isDate: true
                    }
                } else {
                    setStartDate(dates?.startDate);
                    setEndDate(dates?.endDate);
                    setIsDate(true);
                    ch = {
                        ...ch,
                        startDate : dates?.startDate,
                        endDate : dates?.endDate,
                        isDate: true
                    }
                    
                }

                onChange(ch);
                break;
            default: 
        }; 
    };

    useEffect(()=>{
        setIsLoad(true);
        
    }, [dates]);

    useEffect(()=>{
        if(!isRegdate){
            setStartDate(null);
            setEndDate(null);
            setIsDate(false);
            const ch = {
                startDate : null,
                endDate : null,
                isDate: false
            }
            onChange(ch);
        }
    },[isLoad])

    


    return <>
            {isLoad && !isRegdate ? (

                <Select type="button" className="select-button" onSelect={(data)=>{handleSelect(data);}} >
                    <option value="미정" selected>미정</option>
                    <option value="당일">기간선택</option>
                </Select>
            ) : (
                <Select type="button" className="select-button" onSelect={(data)=>{handleSelect(data);}} >
                    <option value="미정">미정</option>
                    <option value="당일" selected>기간선택</option>
                </Select>
            )}
            <div className="date-picker">
            {isDate ? (
                <>
                <DatePicker 
                value={(startDate != null) ? format(startDate,'yyyy년 MM월 dd일') : null} 
                selected={startDate} 
                onChange={(date) => {

                    if(endDate < date) return alert("날짜를 확인해주세요!");
                    setStartDate(date);
                    const ch = {
                        startDate : date,
                        endDate : endDate,
                        isDate: isDate
                    }
                    onChange(ch);
                }} 
                locale={ko} />
                ~
                <DatePicker 
                    value={(endDate != null) ? format(endDate,'yyyy년 MM월 dd일') : null} 
                    selected={endDate} 
                    onChange={(date) => {
                        if(startDate > date) return alert("날짜를 확인해주세요!")
                        
                        setEndDate(date);
                        const ch = {
                            startDate : startDate,
                            endDate : date,
                            isDate: isDate
                        }
                        onChange(ch);
                    }} 
                    locale={ko} />
                </>
             ) : (
                <p>기간 미정</p>
             )}
            </div>
    </>
} 