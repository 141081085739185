import { useEffect, useState } from "react";
import { postAPI, putAPI } from "../../data/data";
import { getCookieKey, getCookieSite } from "../common/cookies/cookiesAuth";
import styled from "styled-components";
import { slideDown } from "../common/component/Slide";
import { Link } from "react-router-dom";


const HomeStyle = styled.div`
    h3{
        background-color: var(--main-color);
        color:#fff;
        padding: 15px 10px;
        border-radius:8px;
    }
    li{
        width: 100%;
        padding: 20px 10px;
        border-bottom: 1px solid #ccc;
        cursor:pointer;
    }
    li dd{
        display:none;
        padding: 20px 0;
    }
    li dt {
        display:flex;
        
    }
    li dt span{
        display:inline-block;
        margin-left: auto;
        color:#ccc;
    }

    table{
        width:100%;
        border:1px solid #ddd;
        text-align:center;
    }
    table thead {background-color:#f9f9f9;}
    table thead th {/* background-color:#343434; */ /* padding: 7px 10px; */ /* color: #fff; */}
    table th,td {text-align:center; border:1px solid #ddd;}
    table thead{background:#f9f9f9}
    table thead th {background: #eaf2f5; color: #6286A8;font-weight: bold;}
    table th{padding:10px 20px 7px; text-align:center}
    table th+th{/* border-right:none */}
    table tbody td:first-child {}
    table td{padding:10px 20px 7px; text-align:center}
    table td.bd{border-right-width:1px;background-color:#f7fcfc}
    table tr.view td {
        opacity : 0.5;
    }
    .more{
        display:flex;
        justify-content: center;
        padding: 40px 15px 10px;
        align-items: center;
    }
    .more a{
        padding: 10px 15px;
        background-color: var(--main-color);
        font-size: 14px;
        color:#fff;
        border-radius: 5px;
    }
    .content_msg{
        padding: 15px 0;
    }
`

export default function Home(){
    
    const [list,setList] = useState([]);
    const [isData, setIsData] = useState(false);

    useEffect(()=> {
        const getMsg = async ()=>{
            const res = await postAPI("/msg/getMsg", null, {site_id: getCookieSite("site_id")});

            setList(res.data);
            if(res.data.length === 0){
                setIsData(false);
            } else {
                setIsData(true);
            }
        }
        getMsg();
    }, [])

    

    return <section>
        <div className="container">
            <div className="content title_box">
                <h2>DASHBOARD</h2>
            </div>

            <InquiryMain isData={isData} list={list} />
        </div>
    </section> 
    
}

const InquiryMain = ({isData, list})=> {

    
    const clickHandle = async (e, msg_idx)=>{
        if(e.currentTarget.classList.contains("active")) return;

        const dd = e.currentTarget.querySelector("dd");

        const res = await putAPI("/msg/putMsg", {msg_idx});

        if(res.data === "SUCCESS"){
            slideDown(dd, 300, e.currentTarget);
        }
    }
    return <>
        {isData && (
            <HomeStyle className="content">
                <h3>문의내용</h3>
                <ul>
                    {list?.map((item,idx)=>{
                        const dateStr = item.regdate;
                        const date = new Date(dateStr);
                        const regdate = `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`;
                        return (
                        <li key={idx} onClick={(e)=> clickHandle(e, item.msg_idx)}>
                            <dl>
                                <dt>{item.msg_title} <span>{regdate}</span></dt>
                                <dd>
                                    <table>
                                        <colgroup>
                                            <col width={"100px"} />
                                        </colgroup>
                                        <tbody>
                                            <tr>
                                                <th>이름</th>
                                                <td>{item.username}</td>
                                            </tr>
                                            <tr>
                                                <th>연락처</th>
                                                <td>{item.phone}</td>
                                            </tr>
                                            <tr>
                                                <th colSpan={2}>내용</th>
                                            </tr>
                                            <tr>
                                                <td colSpan={2}>
                                                    <div className="content_msg">

                                                        {item.msg_content}
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </dd>
                            </dl>
                        </li>
                    )})}
                </ul>
                <div className="more">
                    <Link to={"/orders"}>더보기</Link>
                </div>
            </HomeStyle>
        )}
    </>
}

// const Btn = ({view, msg_idx, content})=>{
//     const [isView, setIsView] = useState(false);

//     const clickHandle = async ()=>{
//         if(view === 2) return setIsView(true);
//         const res = await putAPI("/msg/putMsg", {msg_idx});

//         if(res.data === "SUCCESS"){
//             setIsView(true);
//         }
//     }
//     return <>
//         {isView ? (
//             content
//         ) : (

//             <button type="button" onClick={clickHandle}>내용보기</button>
//         )}
//     </>
// }