import axios from "axios";
import Cookies from "js-cookie"
import { API, V1 } from "../port";
import { toUrlSafeBase64 } from "../../config/base64";
import CryptoJS from 'crypto-js';
import { getCookie, removeCookie, setCookie } from "../../templates/common/cookies/cookiesAuth";

export const getAccessToken = ()=>{
    const token = getCookie("accessToken");
    return token;
}


axios.interceptors.response.use(
    response => response,
    error => {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            return axios.post(API + V1 + '/auth/refresh', {}, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getAccessToken()}`
                }
            })
            .then(res => {
                if (res.status === 200) {
                    const secretKey = 'auth';
                    const admin_id = res.data.admin_id;
                    const admin_idx = res.data.admin_idx;
                    const admin_name = res.data.admin_name;
                    const role = res.data.auth_role;
                    const data = {
                        accessToken : toUrlSafeBase64(CryptoJS.AES.encrypt(res.data.access, secretKey).toString()),
                        SEC_ID : toUrlSafeBase64(CryptoJS.AES.encrypt(admin_id, secretKey).toString()),
                        SEC_IDX : toUrlSafeBase64(CryptoJS.AES.encrypt(admin_idx.toString(), secretKey).toString()),
                        SEC_NAME : toUrlSafeBase64(CryptoJS.AES.encrypt(admin_name, secretKey).toString()),
                        SEC_SEQ_KEY : toUrlSafeBase64(CryptoJS.AES.encrypt(role, secretKey).toString())
                    }


                    setCookie("SESSION_SEC", data);
                    return axios({
                        ...originalRequest,
                        headers: {
                            ...originalRequest.headers,
                            'Authorization': `Bearer ${res.data.access}`,
                        },
                    });
                }
            }).catch(err => {
                removeCookie("SESSION_SEC");
                window.location.reload();
            });
    }
    
    
    
    return Promise.reject(error);
});
