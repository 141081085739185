import { useEffect, useReducer, useRef, useState } from "react";
import styled from "styled-components";
import { design, designList, designOriginalHTML } from "../../../data/homePage/design";
import { useLocation } from "react-router-dom";
import CryptoJS from 'crypto-js';
import { fromUrlSafeBase64 } from "../../../config/base64";
import { postAPI, putAPI, s3Save } from "../../../data/data";
import { FOLDER, S3 } from "../../../data/port";
import { getCookieSite } from "../cookies/cookiesAuth";



const AlpakaContainerStyled = styled.div`
    img{
        width: 100%;
    }
    .Alpaka-container{
        position:fixed;
        left:0;
        top:58px;
        width:100%;
        margin: 0 auto;
        height:calc(100vh - 58px);
        z-index:9999;
        background-color: #fff;
        border-top: 1px solid silver;
        display:flex;
        gap: 15px;
    }
    .Alpaka-container_cover{
        display:flex;
        width: 100%;
    }
    .Alpaka-tool{
        
        width: 300px;
        border-left: 2px solid silver;
        height: 100vh;
        background-color: #eaeaea;
        position:relative;
        overflow: auto
    }
    .Alpaka-tool_inner{
        padding: 0 0 100px 0;
        position:relative;
    }
    .Alpaka-page{
        width: calc(100% - 300px);
        overflow: auto;
        padding: 100px 25px;
    }
    .Alpaka-page_cover{
        min-width: 1200px;
    }
    .Alpaka-pageHTML{
        max-width: 1200px;
        margin: 0 auto;
        border: 3px solid #eaeaea;
        
    }
    .Alpaka-shape_box{
        border: 1px solid #333;
    }
    .Alpaka-current{
        border: 2px dotted silver;
    }
    .sub_content{

    }
    .sub_content_inner{
        position:relative;
        padding: 100px 15px;
        margin: 25px 0;
    }
    // .sub_content_inner::before,
    // .sub_content_inner::after{
    //     content: "";
    //     display:block;
    //     position:absolute;
    //     width: calc(100% + 30px);
    //     height: 1px;
    //     left: -15px;
    //     background-color: red;
    // }
    // .sub_content_inner::before{
    //     top: 0;
    // }
    // .sub_content_inner::after{
    //     bottom:0;
    // }
    .title{
        padding: 20px 0;
        text-align:center;
    }
    .tool_content{
        padding: 25px 10px;
    }
    .fontSize_cover{
        display:inline-block;
        position:relative;
    }
    .sizePX{
        position:absolute;
        left:0;
        width: 100%;
        overflow: auto;
        background-color: #fff;
        line-height: 35px;
        height:0;
        transition:height 0.5s;
    }
    .sizePX.active{
        height: 150px;
    }
    .sizePX li {
        position:relative;
        text-align:center;
        border-radius: 5px;
        cursor: pointer;
    }
    .sizePX li.active{
        background-color: #eaeaea;
    }
    .sizePX li:hover{
        background-color: #eaeaea;
    }
    .sizePX li.active::before{
        content: "";
        display: block;
        position: absolute;
        left: 5px;
        height: 20px;
        width: 5px;
        top: 8px;
        background-color: var(--main-color);
        border-radius: 50px;
    }
    .fontSize_cover input[type=text]{
        width: 100px;
        padding: 10px 5px;
        text-align:center;
        border: 1px solid var(--main-color);
        font-size: 20px;
    }
    .tool_contents{
        padding: 50px 0;
        border-bottom: 3px dotted #333;
    }
    
    .tool_contents p{
        padding: 25px 0;
        font-size: 18px;
        font-weight: 700;
    }
    .font-cover{
        display:flex;
        justify-content: center;
        align-items: center;
        
    }
    input[type="color"] {
        appearance: none;
        -webkit-appearance: none;
        padding: 5px;
        border: none;
        border-radius: 5px;
        outline: none;
        cursor: pointer;
        width: 100%;
        height: 50px;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    }
    .addStyle{
        max-width: 1200px;
        margin: 0 auto;
        padding: 25px 0px;
        padding-top: 0;
        display:flex;
        gap: 20px;
    }
    .save_btn{
        position: fixed;
        bottom: 0;
        right: 0;
        width: 300px;
        padding: 0 10px;

    }
    .save_btn button{
        width: 100%;
    }
    .addStyle_content li{
        padding: 25px 0;
        cursor: pointer;
    }
    .addStyle_content li p{
        font-size: 18px;
        font-weight: 700;
        padding-bottom: 5px;
    }
    
`;

const HTMLstyle = styled.div`
    .sub_content_inner{
        padding: 50px 25px;
        display: flex;
        justify-content: space-between;
        align-items: start;
        gap: 20px;
    }
    .sub_title{
        font-size: 27px;
        color: blue;
        margin-bottom: 20px;
    }
    .sub_title_sub{
        font-size: 16px;
        font-weight: 300;
        color: #666
    }
    .left{
        width: 100%;
        position: relative;
        display: flex;
    }
    .right{
        width: 100%;
        overflow: hidden;
        position: relative;
    }
    .radis{
        border-radius: 20px;
        overflow:hidden;
    }
    img{
        100%
    }

    .imge_static{
        width: 50%;
    }
    .imge_free{
        width: 50%;
        margin-left: -100px;
        margin-top: 100px;
    }
`;



const StyleList = ({obj, onClicked})=> {
   
    return <>
        <ul className="addStyle_content">
            {obj.map((item,idx)=>(
                <li key={idx} onClick={() => onClicked(item.type)}>
                    <p>{item.title}</p>
                    <img src={item.img} alt={item.title} />
                </li>
            ))}
        </ul>
    </>
}

const ACTION_TYPE_HTML = {
    changeHTML : "changeHTML",
    styleHTML : "styleHTML",
    selectHTML : "selectHTML"
}

const ACTION_TYPE_TOOL = {
    changeType : "changeType", // 툴 타입
    changeFontSize: "changeFontSize", //폰트 사이즈 변경
    changeFontWeight: "changeFontWeight", //폰트굵기 변경
    changeColor: "changeColor", //색상 변경
    changeFile:"changeFile", //파일 변경
    changeImage: "changeImage", // 이미지 변경
    focusFontSize : "focusFontSize", //폰트사이즈 포커스 여부
    focusFontWeight : "focustFontWeight" // 폰트굵기 포커스 여부
}


const FONTWEIGHT = [100,200,300,400,500,600,700,800,900];
const FONTSIZE = [8,9,10,11,12,13,14,16,18,20,22,24,26,28,36,48,52];

const initialStateHTML = {
    htmlSave: false,
    menu_idx : 1,
    menu_name : "",
    menu_type_idx : 1,
    type_title : "",
    thisHtml : "",
    selectHtml : null,
    selectRange : null,
    fileList : []
}

const initialStateTool = {
    toolType : "add",
    fontSize: 16,
    fontWeight: 300,
    color: "#000000",
    file:"",
    fontList : FONTSIZE,
    WeightList : FONTWEIGHT,
    imageSrc: null,
    fontSizeFocus : false,
    fontWeightFocus : false,
    target :null
}


const selectDrag = ()=>{
    const selection = window.getSelection();
    if (selection && selection.toString().length > 0) {
        const range = selection.getRangeAt(0);
        return range;
    }
}



/* 선택된 텍스트에 span태그로 감싸기 */
const wrapSelectedText = () => {
    const selection = window.getSelection();
    if (selection && selection.toString().length > 0) {
        const range = selection.getRangeAt(0);
        
        if (range.startContainer.nodeType === Node.TEXT_NODE && range.endContainer.nodeType === Node.TEXT_NODE) {
            const span = document.createElement("span");
            try {
                range.surroundContents(span);
                return span;
            } catch(e) {
                const selectedText = selection.toString();
                
                
                const spanText = range.endContainer.textContent;
                const notSpanText = selectedText.replace(spanText, '');
                
                span.innerHTML = notSpanText;
                // console.log('Span text:', spanText);
                // console.log('Not span text:', notSpanText);
                return span;
            }
        } else {
            return null;
        }
    }
};




/* rgba 를 # 형식으로 변경하기 */
const rgbaToHex = (rgba)=> {
    const values = rgba
      .substring(rgba.indexOf("(") + 1, rgba.lastIndexOf(")"))
      .split(",")
      .map((value) => parseInt(value.trim(), 10));
  
    const hex = "#" + values.map((value) => value.toString(16).padStart(2, "0")).join("");
    return hex;
}

/* 파일업로드 */
const fileUpload = (e, dom) =>{
    const file = e.target.files[0];

    if (file) {
        const reader = new FileReader();

        reader.onloadend = () => {
            const target = dom?.tagName.toLowerCase();
            const fileType = file.type;
            if(!fileType.includes("image")) return null;
            if(target === "img"){
                
                dom.setAttribute("src", reader.result);
            }
        };

        reader.readAsDataURL(file);
    }
}



const htmlReducer = (state, action)=>{
    
    switch(action.type){
        case ACTION_TYPE_HTML.changeHTML :
            if(state.thisHtml == null) state.thisHtml = "";
            if(action.htmlSave){
                return {
                    ...state,
                    thisHtml : action.thisHtml
                };
            }else {
                return {
                    ...state,
                    thisHtml : state.thisHtml + action.thisHtml
                };
            }
        case ACTION_TYPE_HTML.selectHTML :
            action.selectHtml?.classList.add("Alpaka-current");
            state.selectHtml?.classList.remove("Alpaka-current");
            
            if(action.isTarget && state.selectHtml === action.selectHtml && action.tagName != "img"){
                
                action.selectHtml?.setAttribute("contentEditable", true);
                action.selectHtml?.focus();
            } else if(action.tagName == "img"){
                state.selectHtml?.setAttribute("contentEditable", false);
            }
            
            
            return {
                ...state,
                selectRange : action.selectRange,
                selectHtml : action.selectHtml
            }
        default : return state
    }
}

const toolReducer = (state, action)=>{
    
    switch(action.type){
        case ACTION_TYPE_TOOL.toolType :
            return {
                ...state,
                toolType : action.toolType
            }
        case ACTION_TYPE_TOOL.changeColor :
            if(action.target){
                action.target.style.color=`${action.color}`;
            }
            return {
                ...state,
                color : action.color
            }
        case ACTION_TYPE_TOOL.changeFontSize :
            if(action.target){
                action.target.style.fontSize=`${action.fontSize}px`;
            } 
            
            return {
                ...state,
                fontSize : action.fontSize
            }
        case ACTION_TYPE_TOOL.changeFontWeight :
            if(action.target){
                action.target.style.fontWeight=`${action.fontWeight}`;
            }
            return {
                ...state,
                fontWeight : action.fontWeight
            }
        case ACTION_TYPE_TOOL.focusFontSize :
            
            return {
                ...state,
                fontSizeFocus : action.fontSizeFocus
            }
        case ACTION_TYPE_TOOL.focusFontWeight :
            
            return {
                ...state,
                fontWeightFocus : action.fontWeightFocus
            }
        default : return state;
    }
}


export const AlpakaEditor = ()=>{

    const htmlRef = useRef(null);
    const toolRef = useRef(null);
    const [htmlState, htmlDispatch] = useReducer(htmlReducer, initialStateHTML);
    const [toolState, toolDispatch] = useReducer(toolReducer, initialStateTool);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const secretKey = 'menu_idx';
    const editNumber = fromUrlSafeBase64(queryParams.get('editNumber'));
    const bytes = CryptoJS.AES.decrypt(editNumber, secretKey);
    const menu_idx = Number(bytes.toString(CryptoJS.enc.Utf8));
    const [images, setImages] = useState([]);

    
    
    
    // 이벤트 함수들
    const eventFunction = {
        /* state 관리 */
        fontSize : (size) => {
            const target = (!htmlState.selectRange) ? ((!htmlState.selectHtml) ? null : htmlState.selectHtml) : htmlState.selectRange;
            toolDispatch({type : ACTION_TYPE_TOOL.changeFontSize , fontSize : size, target : target});
        },
        fontWeight : (weight) => {
            const target = (!htmlState.selectRange) ? ((!htmlState.selectHtml) ? null : htmlState.selectHtml) : htmlState.selectRange;
            toolDispatch({type : ACTION_TYPE_TOOL.changeFontWeight , fontWeight : weight, target : target});
        },
        color : (color) => {
            const target = (!htmlState.selectRange) ? ((!htmlState.selectHtml) ? null : htmlState.selectHtml) : htmlState.selectRange;
            toolDispatch({type : ACTION_TYPE_TOOL.changeColor , color : color, target : target});
        },
        /* 폰트 사이즈 input 박스 클릭시 오픈시킴 */
        fontSizeFocus : (division)=> fontSizetimerFocus(division),
        fontWeightFocus : (division)=> fontWeighttimerFocus(division),

        /* 툴타입 변경 */
        changeTool :  (type) => toolDispatch({type: ACTION_TYPE_TOOL.toolType, toolType : type}),
        changeHTML : (html, save)=> htmlDispatch({type: ACTION_TYPE_HTML.changeHTML, thisHtml: html, htmlSave : save})
    }

    useEffect(()=>{
        const fetchData = async () => {
            const data = await postAPI("/menu/html",htmlRef.current, {menu_idx: menu_idx});
            eventFunction.changeHTML(data.data.html, true);
        };
        fetchData();
    },[]);

    


    /* onClick 과 onFocus 충돌로 setTImeout 설정 */
    const fontSizetimerFocus = (division) => {
        if(!division) {
            setTimeout(()=>{
                toolDispatch({type : ACTION_TYPE_TOOL.focusFontSize, fontSizeFocus : division})
            }, 10)
        } else {
            toolDispatch({type : ACTION_TYPE_TOOL.focusFontSize, fontSizeFocus : division})
        }
    }
    const fontWeighttimerFocus = (division) => {
        if(!division) {
            setTimeout(()=>{
                toolDispatch({type : ACTION_TYPE_TOOL.focusFontWeight, fontWeightFocus : division})
            }, 10)
        } else {
            toolDispatch({type : ACTION_TYPE_TOOL.focusFontWeight, fontWeightFocus : division})
        }
    }

    /* 파일업로드 */
    const fileUpload = (e, dom) =>{
        const file = e.target.files[0];

        if (file) {
            const reader = new FileReader();
            const fileExt = file.name.split('.').pop();
            
            
            // 확장자 제한
            if (!['jpeg', 'png', 'jpg', 'JPG', 'PNG', 'JPEG'].includes(fileExt)) {
                alert('jpg, png, jpg 파일만 업로드가 가능합니다.');
                return;
            }
            

            reader.onloadend = () => {
                dom.setAttribute("src", reader.result);

                const fileName = Math.random().toString(36).substring(2, 15) + Date.now().toString(36);
                const newFileName = fileName + '.' + fileExt;
                
                
                setImages(prevImages => [...prevImages, {dom, newFileName, file }]);
            };

            reader.readAsDataURL(file);
        }
    }


    /* 수정할 박스 클릭해서 수정하기 */
    const currentClick = (e)=> {
        const target = e.target;
        const thisTag = target.tagName.toLowerCase();
        const allowedTags = ["p", "h3", "img"];

        
        if (allowedTags.includes(thisTag)) {
            
            // eventFunction.color(colorselected);
            htmlDispatch({type: ACTION_TYPE_HTML.selectHTML, selectHtml : target, isTarget : true, tagName : thisTag})

            if(thisTag != "img"){
                const fontSizeSelected = window.getComputedStyle(target).getPropertyValue("font-size").replace("px", "");
                const fontWeightSelected = window.getComputedStyle(target).getPropertyValue("font-weight");
                const colorselected = rgbaToHex(window.getComputedStyle(target).getPropertyValue("color"));
                
                eventFunction.fontSize(fontSizeSelected);
                eventFunction.fontWeight(fontWeightSelected);
                eventFunction.color(colorselected);
                eventFunction.changeTool("text");
                
            } else {
                eventFunction.changeTool("img");
            }
            
        } else{
            htmlDispatch({type: ACTION_TYPE_HTML.selectHTML, selectHtml : null, isTarget : false});
            eventFunction.changeHTML(e.currentTarget.innerHTML, true);
            //htmlDispatch({type: ACTION_TYPE_HTML.changeHTML, thisHtml : e.currentTarget.innerHTML, htmlSave : true});
        }
    }

    /* 툴바 클릭 */
    const toolClick = ()=> {
        
    }

    const focusOut = (e)=>{
        
        eventFunction.changeHTML(e.currentTarget.innerHTML, true);
        if(e.target.innerText.length != 0)
            htmlDispatch({type : ACTION_TYPE_HTML.selectHTML, selectRange : wrapSelectedText(), selectHtml : e.target});
        else 
            e.target.innerText = "이곳에 텍스트를 넣어주세요!! (될수있으면 텍스트는 한글에서 작성후 붙여넣어주세요)";
    }

    
    
    useEffect(()=>{
        const current = htmlRef.current;
        const tool = toolRef.current;
        
        current.addEventListener("click", currentClick);
        current.addEventListener("focusout", focusOut);
        //tool.addEventListener("click", toolClick);
        return ()=>{
            current.removeEventListener("click", currentClick);
            current.removeEventListener("focusout", focusOut);
            //tool.removeEventListener("click", toolClick);
        }
    }, [htmlRef]);

    const saveHTML = async ()=> {
        const src = S3;
        let result = true;
        
        for(let image of images){
            try{

                const path = `${getCookieSite("folder")}/page/${image.newFileName}`;
                const newFile = new File([image.file], image.newFileName, { type: image.file.type });
                const save = await s3Save(newFile, path);
                
                result = true;
            }catch(ex){
                result = false;
                    
                return alert("등록에 실패했습니다. \n에러내용 : " + ex);
            }
        }

        if(result){
            eventFunction.changeHTML(htmlRef.current.innerHTML, true);
            
            const dataType = async () => {
                const data = {
                    menu_idx : menu_idx,
                    html : htmlRef.current.innerHTML
                }
                const put = await putAPI("/menu/editHtml", data);
                if(put.data == "SUCCESS"){
                    alert("저장되었습니다.");
                    window.location.reload();
                }
            }

            dataType();
        }
    }
   


    return <AlpakaContainerStyled>
        <div className="Alpaka-container">
            <div className="Alpaka-container_cover">
                <div className="Alpaka-page">
                    <div className="addStyle">
                        <button type="button" onClick={()=>{
                            eventFunction.changeTool("add");
                            eventFunction.changeHTML(htmlRef.current.innerHTML, true)
                        }}>타입 추가하기</button>

                        <button type="button">미리보기</button>
                    </div>
                    <div className="Alpaka-page_cover">
                        <HTMLstyle className="Alpaka-pageHTML" ref={htmlRef} dangerouslySetInnerHTML={{__html: htmlState.thisHtml}}></HTMLstyle>
                    </div>
                </div>
            {/* ... other tools */}
            <div className="Alpaka-tool" ref={toolRef}>
                <div className="Alpaka-tool_inner">
                    <div className="title">
                        <h2>편집기 도구함</h2>
                    </div>
                    <div className="tool_content">
                        {toolState.toolType === "text" && (
                            <>
                                <div className="tool_contents font">
                                    <p>폰트사이즈 변경</p>
                                    <div className="font-cover">
                                        <span className="fontSize_cover">
                                            <input type="text" 
                                                onBlur={()=>eventFunction.fontSizeFocus(false)} 
                                                onFocus={()=>eventFunction.fontSizeFocus(true)} 
                                                value={toolState.fontSize} 
                                                onChange={(e)=>eventFunction.fontSize(e.target.value)}
                                            />
                                            <ul className={`sizePX ${(toolState.fontSizeFocus) ? "active" : ""}`}>
                                                {toolState.fontList.map((item, idx)=>(
                                                    <li key={idx} className={(toolState.fontSize == item) ? "active" : ""} data={item} onClick={()=>{eventFunction.fontSize(item)}}>{item}</li>
                                                ))}
                                            </ul>
                                        </span>
                                        <span style={{fontSize: "20px",marginLeft: "5px"}}>px </span>
                                    </div>
                                </div>

                                <div className="tool_contents font">
                                    <p>폰트굵기 변경</p>
                                    <div className="font-cover">
                                        <span className="fontSize_cover">
                                            <input type="text" 
                                                onBlur={()=>eventFunction.fontWeightFocus(false)} 
                                                onFocus={()=>eventFunction.fontWeightFocus(true)} 
                                                value={toolState.fontWeight} 
                                                onChange={(e)=>e.preventDefault()}

                                            />
                                            <ul className={`sizePX ${(toolState.fontWeightFocus) ? "active" : ""}`}>
                                                {toolState.WeightList.map((item, idx)=>(
                                                    <li key={idx} className={(toolState.fontWeight == item) ? "active" : ""} data={item} onClick={(e)=>eventFunction.fontWeight(item)}>{item}</li>
                                                ))}
                                            </ul>
                                        </span>
                                    </div>
                                </div>
                                <div className="tool_contents color" style={{textAlign:"center"}}>
                                    <p style={{textAlign:"left"}}>색상 변경</p>
                                    <input type="color" value={toolState.color} onChange={(e)=>eventFunction.color(e.target.value)} />
                                </div>
                            </>
                        )}
                        {toolState.toolType === "img" && (
                            <div className="tool_contents images">
                                <p>이미지 파일 변경</p>
                                <input type="file" accept="image/*" value={toolState.file} onChange={(e)=>fileUpload(e, htmlState.selectHtml)} />
                            </div>
                        )}

                        {toolState.toolType === "add" && (
                            <StyleList obj={designList()} onClicked={(type)=>eventFunction.changeHTML(design(type)[0].html, false)}/>
                        )}
                    </div>
                    <div className="save_btn">
                        <button type="button" onClick={saveHTML}>저장</button>
                    </div>
                </div>
            </div>
          </div>
        </div>

    </AlpakaContainerStyled>
}