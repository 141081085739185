import { useEffect, useReducer, useState } from "react";
import { postAPI } from "../../../data/data";
import styled from "styled-components";
import CryptoJS from 'crypto-js';
import { fromUrlSafeBase64, toUrlSafeBase64 } from "../../../config/base64";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { getCookie, getCookieKey, getCookieSite } from "../../common/cookies/cookiesAuth";
const BoardListStyle = styled.div`
    .content{
        display:flex;
        justify-content:space-between;
        align-items:center;
    }
    .page_btn{
        margin-left: auto;
        padding: 0;
    }
    .page_btn a{
        padding: 10px 20px;
        display:block;
        color:#fff;
    }
    .content_notice{
        width:100%;
    }
    .content_notice table{
        width:100%;
        text-align:center;
    }
    .title{
        display:flex;
        align-items: center;
    }
    .title span.arrow{
        margin-left: auto;
        display: block;
        width: 30px;
        height: 30px;
        position:relative;
        cursor: pointer;
    }
    .title span.arrow::before, .title span.arrow::after{
        content: "";
        display:inline-block;
        top: 50%;
        position: absolute;
        width: 10px;
        height: 1px;
        background-color :#333;
    }
    .title span.arrow::before{
        left:7px;
        transform : rotate(45deg);
    }
    .title span.arrow::after{
        right: 6px;
        transform : rotate(-45deg);
    }

    .state-wrap {
        overflow: hidden;
        padding: 20px 10px 20px 0;
        background-color: rgba(255,255,255,.3);
        margin: 5px 0;
    }
    .state-group strong { display: inline-block;border-left:5px solid #3d3d3d; padding-left: 5px;}
    .state-group {float: left;}
    .state {font-size: .95em;text-align: center;}
    .state-group .state + .state {margin-left: 10px;}
    .state span {padding: 3px 2px 1px 2px; display: inline-block;width: 18px;height: 18px; font-size: 12px;color: #fff;}
    .state .undecided {border-radius:20px;background-color: #0abab5;}
    .state .edit {border-radius:20px;background-color: #82cf54;}
    .state .delete {border-radius:20px;background-color: #fc2d59;}
    .state .complete {border-radius:20px;background-color: #4169e1;}
    .total {float: right;font-size: 15px;color: #fff;}
    .total span {font-size: 13px;}
    .per {background:#525252;display: inline-block;padding: 2px 10px 4px; margin-right: 20px;}
    .status { background-color: #5f6061; display: inline-block;padding: 2px 10px 4px;}
    .quest {background: #f9f1d0;}
    .comp {background: #f2f2f2;}
    .result select {
        border: 0;
        background-color:#525252;
        font-size: 14px;
        color:#fff;
        text-align:center;
        cursor: pointer;
    }

`;

export const BoardList = ()=> {
    const [menuList, setMenuList] = useState(null);
    
    const secretKey = 'menu_idx'; // 비밀 키
    const secretNameKey = 'menu_name'; // 비밀 키


    const site_id = getCookieSite("site_id");
    useEffect(()=>{
        const fetchData = async () => {
            const data = await postAPI("/menu/list",null,{site_id});
            setMenuList(data.data);
        };
        fetchData();
    },[]);
    return <BoardListStyle>
        <section>
            <div className="container">
                <div className="content title_box">
                    <h2>게시판 관리</h2>
                </div>
                {menuList?.map((item, idx)=>(
                    item.menu_type_idx == 2 && 

                <div key={idx} className="content">
                    <span>{item.menu_name}</span>
                    {/*onClick={()=> setContent(<AlpakaEditor menu_idx={item.menu} />)}*/}
                    <button type="button" className="page_btn" >
                        <Link to={`/notice/boardSetting?p=${toUrlSafeBase64(CryptoJS.AES.encrypt(item.menu_idx.toString(), secretKey).toString())}&n=${toUrlSafeBase64(CryptoJS.AES.encrypt(item.menu_name.toString(), secretNameKey).toString())}
                            `}>
                            자세히 보기
                        </Link>
                    </button>
                </div>
                ))}
            </div>
        </section>
    </BoardListStyle>
}