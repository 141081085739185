import { Link, useLocation } from "react-router-dom";
import CryptoJS from 'crypto-js';
import { fromUrlSafeBase64 } from "../../../config/base64";
import { useReducer, useState } from "react";
import { AlpakaPaging } from "../../common/component/AlpakaPaging/AlpakaPaging";
import styled from "styled-components";
import SearchTool from "../../common/component/Search/Search";
import { MdManageSearch } from "react-icons/md";


const BoardSettingStyle = styled.div`

.title{
    display:flex;
    align-items: center;
}
.title span.arrow{
    margin-left: auto;
    display: block;
    width: 30px;
    height: 30px;
    position:relative;
    cursor: pointer;
}
.title span.arrow::before, .title span.arrow::after{
    content: "";
    display:inline-block;
    top: 50%;
    position: absolute;
    width: 10px;
    height: 1px;
    background-color :#333;
}
.title span.arrow::before{
    left:7px;
    transform : rotate(45deg);
}
.title span.arrow::after{
    right: 6px;
    transform : rotate(-45deg);
}

.state-wrap {
    overflow: hidden;
    padding: 20px 10px 20px 0;
    background-color: rgba(255,255,255,.3);
    margin: 5px 0;
}
.state-group strong { display: inline-block;border-left:5px solid #3d3d3d; padding-left: 5px;}
.state-group {float: left;}
.state {font-size: .95em;text-align: center;}
.state-group .state + .state {margin-left: 10px;}
.state span {padding: 3px 2px 1px 2px; display: inline-block;width: 18px;height: 18px; font-size: 12px;color: #fff;}
.state .undecided {border-radius:20px;background-color: #0abab5;}
.state .edit {border-radius:20px;background-color: #82cf54;}
.state .delete {border-radius:20px;background-color: #fc2d59;}
.state .complete {border-radius:20px;background-color: #4169e1;}
.total {float: right;font-size: 15px;color: #fff;}
.total span {font-size: 13px;}
.per {background:#525252;display: inline-block;padding: 2px 10px 4px; margin-right: 20px;}
.status { background-color: #5f6061; display: inline-block;padding: 2px 10px 4px;}
.quest {background: #f9f1d0;}
.comp {background: #f2f2f2;}
.result select {
    border: 0;
    background-color:#525252;
    font-size: 14px;
    color:#fff;
    text-align:center;
    cursor: pointer;
}
.search_tools{
    padding-bottom: 50px;
    display:block;
    text-align:right
}
.search_tools span{
    display: inline-block;
}
h2{
    display: flex;
    justify-content: start;
    align-items: center;
}
h2 button{
    padding: 0;
    margin-left: auto;
}
h2 button a{
    display:block;
    color:#fff;
    padding: 10px 20px;
}
`;

const SarchBtn = ()=> {
    return <>
        <MdManageSearch style={{marginLeft: "auto", display: "block", fontSize: "38px", color: "var(--main-color)",cursor:"pointer"}} />
    </>
}

export const BoardSetting = () => {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const secretKey = 'menu_idx';
    const secretNameKey = 'menu_name';
    const editNumber = fromUrlSafeBase64(queryParams.get('p'));
    const editName = fromUrlSafeBase64(queryParams.get('n'));
    const bytes = CryptoJS.AES.decrypt(editNumber, secretKey);
    const bytesName = CryptoJS.AES.decrypt(editName, secretNameKey);
    const [searchCom, setSearchCom] = useState(false);
    const menu_idx = Number(bytes.toString(CryptoJS.enc.Utf8));
    const menu_name = bytesName.toString(CryptoJS.enc.Utf8);
    const [limit, setLimit] = useState(10);
    const searchRang = [
        {
            title : "제목",
            value : "notice_title"
        },
        {
            title : "내용",
            value : "notice_content"
        },
        {
            title : "작성자",
            value : "notice_writer"
        }
    ];

    const column = [
        {
            title : "No",
            column : "notice_idx",
            link : null
        },
        {
            title : "제목",
            column : "notice_title",
            link : "/notice/boardEdit"
        },
        {
            title : "작성자",
            column : "notice_writer",
            link : null
        },
        {
            title : "날짜",
            column : "notice_regdate",
            link : null
        },
        {
            title : "상태",
            column : "notice_state",
            link : null
        }
    ]
    

    const [search, setSearch] = useState({
        notice_title: "",
        notice_content : "",
        notice_writer : "",
        order : 0,
        start_date: "",
        end_date: "",
    });

    const searchHandle = (obj)=> {
        const startDate = (obj.start_date != "") ? obj.start_date.toISOString().split('T')[0] : "";
        const endDate = (obj.end_date != "") ? obj.end_date.toISOString().split('T')[0] : "";
        
        if (obj.column == "notice_title") {
            setSearch((prev)=>{
                    return {
                        ...prev,
                        notice_title: obj.searchTerm,
                        notice_content : "",
                        notice_writer : "",
                        start_date : startDate,
                        end_date : endDate,
                        order : obj.order
                    }
            })
        } else if(obj.column == "notice_content"){
            setSearch((prev)=>{
                return  {
                    ...prev,
                    notice_title: "",
                    notice_content : obj.searchTerm,
                    notice_writer : "",
                    start_date : startDate,
                    end_date : endDate,
                    order : obj.order
                }
            })

        } else if(obj.column == "notice_writer"){
            setSearch((prev)=>{
                return {
                    ...prev,
                    notice_title: "",
                    notice_content : "",
                    notice_writer : obj.searchTerm,
                    start_date : startDate,
                    end_date : endDate,
                    order : obj.order
                }
            })
        }
    }

    

    return <BoardSettingStyle>
        <section>
            <div className="container">
                <div className="content title_box">
                    <h2><span>{menu_name}</span><button type="button"><Link to={`/notice/board?p=${queryParams.get('p')}`}>게시글 작성</Link></button></h2>
                </div>
                <div className="content">
                    {searchCom ? (
                        <>
                            <SearchTool searchChange={(obj)=> searchHandle(obj)} searchRang={searchRang}></SearchTool>
                        </>
                    ) : (
                        <div className="search_tools" >
                            <span onClick={()=> setSearchCom(true)}><SarchBtn/></span>
                        </div>
                    )}
                    <div className="content_notice">
                        <div className="state-wrap">
                            <div className="state-group">
                                <strong>상태</strong> : &nbsp;
                                <span className="state"><span className="undecided"></span></span> 정상
                                <span className="state"><span className="edit"></span></span> 수정됨
                                <span className="state"><span className="delete"></span></span> 비공개
                            </div>
                            <p className="total">
                                <span className="per">게시물 : 
                                    <span className="result">
                                        <select value={limit} onChange={(e)=>setLimit(e.target.value)}>
                                            <option value={10}>10</option>
                                            <option value={30}>30</option>
                                            <option value={50}>50</option>
                                            <option value={100}>100</option>
                                        </select>
                                        개씩 보기
                                    </span>
                                </span>
                                <span className="comp"></span>
                            </p>
                        </div>
                        <AlpakaPaging item={
                            {
                                menu_idx: menu_idx,
                                notice_title: search.notice_title,
                                notice_content: search.notice_content,
                                notice_writer: search.notice_writer,
                            }}
                            column = {column}
                            secretKey = {"notice_idx"}
                            api={"/notice/getList"}
                            btnLimit={5}
                            limit={limit}
                            order={search.order}
                            start_date={search.start_date}
                            end_date={search.end_date}
                            >
                        </AlpakaPaging>
                    </div>
                </div>
            </div>
        </section>
    </BoardSettingStyle>
}