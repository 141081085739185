import { CKEditor } from '@ckeditor/ckeditor5-react';
import MyUploadAdapter from '../../common/component/MyUpload';
import Select from '../../common/component/Select';
import { useLocation } from 'react-router-dom';
import { fromUrlSafeBase64 } from '../../../config/base64';
import CryptoJS from 'crypto-js';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { postAPI, putAPI, s3Save } from '../../../data/data';
import { FOLDER } from '../../../data/port';
import { v4 as uuidv4 } from 'uuid';
import Cookies from 'js-cookie';
import { getCookie, getCookieKey, getCookieSite } from '../../common/cookies/cookiesAuth';
import { TextEditor } from '../../common/component/Editor/TextEditor';

const EditorStyle =styled.div`
.ck-rounded-corners .ck.ck-editor__editable:not(.ck-editor__nested-editable), 
.ck.ck-editor__editable.ck-rounded-corners:not(.ck-editor__nested-editable){
    min-height: 500px;
    border: 1px solid #ccc;
}
p.sub_title{
    display:block;
    position:relative;
    padding-left: 25px;
    margin-bottom: 15px;
}
p.sub_title::before{
    position: absolute;
    display:block;
    content:"";
    left: 0;
    top: 0;
    width: 6px;
    height: 100%;
    border-radius: 20px;
    background-color: var(--main-color);
}
.btn {
    padding-top: 25px;
    display:flex;
    justify-content: center;
    gap: 30px;
}
.cancel{
    background-color: red;
}

`
const H3Style = styled.h3`
padding-bottom: 100px;
input[type=text]{
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 15px 5px;
}
p.sub_title{
    display:block;
    position:relative;
    padding-left: 25px;
    margin-bottom: 15px;
}
p.sub_title::before{
    position: absolute;
    display:block;
    content:"";
    left: 0;
    top: 0;
    width: 6px;
    height: 100%;
    border-radius: 20px;
    background-color: var(--main-color);
}
select{
    width: 100%;
    padding: 15px 5px;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-top: 20px;
}
` 

export default function Board(){
    const [uploadedFiles,setUploadedFiles] = useState([]);
    const [isUpload, setIsUpload] = useState(false);
    const [menuList, setMenuList]= useState(null);
    const editorRef = useRef(null);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const secretKey = 'menu_idx';
    const editNumber = (queryParams.get('p')) ? fromUrlSafeBase64(queryParams.get('p')) : 0;
    const bytes = CryptoJS.AES.decrypt(editNumber, secretKey);
    const n_dx = Number(bytes.toString(CryptoJS.enc.Utf8));
    const [menu_idx, setMenu_idx] = useState(n_dx);

    const site_id = getCookieSite("site_id");

    const admin_name = getCookie("SEC_NAME");

    const [item, setItem] = useState({
        title : "",
        content : "",
        regdate : "",
    });



    useEffect(()=>{
        const fetchData = async () => {
            const data = await postAPI("/menu/list",null, {site_id});
            setMenuList(data.data);
        };
        fetchData();
    },[]);

    const upload = ()=>{
        const content = editorRef.current.getData();
        if(menu_idx == 0) return alert("게시판을 선택하세요");
        if(content == "" && item.title == "") return alert("내용을 입력해주세요!");
        

        const fetchData = async () => {
            const data = await postAPI("/notice/setNotice",null,
            {
                menu_idx : menu_idx,
                notice_title: item.title, 
                notice_content : content,
                notice_state : 1,
                notice_writer: admin_name
            });

            
            if(data.data === "SUCCESS"){
                alert("게시글 작성이 완료되었습니다.");
                window.history.back();
            }
           // console.log(data.data);
        };
        fetchData();

    }
    
    return <section>
    <div className="container">
        
        <div className="content">
            <h3>게시글 작성</h3>
            <H3Style className=''>
                <select value={menu_idx} onChange={(e)=>setMenu_idx(e.target.value)}>
                    <option value={0}>게시판 선택</option>
                    {menuList?.map((item, idx)=>(
                        item.menu_type_idx == 2 && 
                        <option key={idx} value={item.menu_idx} >{item.menu_name}</option>
                    ))}
                </select>
            </H3Style>
        </div>

        <div className='content'>
            <div className="main_content">
                <H3Style>
                    <p className='sub_title'>제목</p>
                    <input placeholder='제목' type="text" value={item.title} onChange={(e)=>setItem((prev) => {return {
                    ...prev,
                    title: e.target.value
                }})} />
                </H3Style>
                {/* 
                editor: 이 옵션은 CKEditor 5의 빌드를 결정합니다. Classic, Inline, Balloon, Document 등 다양한 종류의 에디터 빌드를 사용할 수 있습니다.
                config: 에디터의 동작을 제어하는 설정을 지정합니다. 툴바의 기능, 언어, 폰트 등을 설정할 수 있습니다.
                data: 에디터에 초기로 로드될 데이터를 지정합니다. HTML 문자열을 사용하여 기본 텍스트를 설정할 수 있습니다.
                onInit: 에디터가 준비되었을 때 호출되는 콜백 함수를 지정합니다.
                onChange: 에디터의 데이터가 변경되었을 때 호출되는 콜백 함수를 지정합니다.
                onBlur: 에디터에서 포커스가 벗어났을 때 호출되는 콜백 함수를 지정합니다.
                onFocus: 에디터가 포커스를 받았을 때 호출되는 콜백 함수를 지정합니다.
                */}
                <EditorStyle>
                <p className='sub_title'>내용</p>
                <TextEditor 
                    refs={editorRef}
                    newPath={"notice"}
                />

                
                <div className='btn'>
                    <button type='button' onClick={()=>upload()}>작성</button>
                    <button type='button' className='cancel' onClick={()=>{
                        if(window.confirm("취소 할 경우 저장이 안될수있습니다. \n정말 취소하시겠습니까?")){
                            window.history.back();
                        }
                    }}>취소</button>
                </div>
                </EditorStyle>
                
            </div>
        </div>
    </div>
</section>
}
