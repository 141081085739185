import { useEffect, useMemo, useRef, useState } from "react";
import { AlpakaPagingButtonStyle, PagingStyle } from "./PagingStyle"
import { postAPI } from "../../../../data/data";
import { Link } from "react-router-dom";

import CryptoJS from 'crypto-js';
import { toUrlSafeBase64 } from "../../../../config/base64";

/*
btnLimit 버튼보이는 갯수
pageTotal 데이터 리스트 총 갯수
limit 한 페이지 최대 갯수
page 현재페이지
*/
export const AlpakaPagingButton = ({pageTotal, limit, btnLimit, page = 1, onClicked}) => {
    const [pages, setPages] = useState(1);
    const [num, setNum] = useState(page);
    const pagingMemo = useMemo(()=>{
        const totalPages = Math.ceil(pageTotal / limit);
        const visibleButtons = Math.min(btnLimit, totalPages);
        const maxNext = Math.ceil(totalPages / visibleButtons);
        const buttonNumbers = [];
        for(let i=num; i <= visibleButtons * pages; i++){
            buttonNumbers.push(i);
            if(i >= totalPages){
                break;
            }
        }
        return {
            totalPages,
            visibleButtons,
            maxNext,
            buttonNumbers
        }

    }, [pages, num, pageTotal, limit]);

    
    
    return (
        <AlpakaPagingButtonStyle>
            <div className="btn_container">
                <div className={`prev ${(1 == pages) ? "": "active"}`} onClick={()=>{
                    if(pages == 1) return;
                    const newNum = num - btnLimit;
                    setNum(newNum);
                    setPages((prevPages)=> prevPages - 1);
                    onClicked(newNum);
                }}></div>
                <ul>
                    {pageTotal != 0 && pagingMemo.buttonNumbers.map((item, idx) => (
                        <li className={(item === page) ? "active" : ""} key={idx} onClick={() => {
                            onClicked(item);
                        }}>
                        {item}
                        </li>
                    ))}
                </ul>
                <div className={`next ${(pagingMemo.maxNext == pages) ? "" : (pageTotal == null) ? "" : "active"}`} onClick={() => {
                    if(pages == pagingMemo.maxNext) return;
                    const newNum = (btnLimit * pages) + 1;
                    setNum(newNum);
                    setPages((prevPages)=> prevPages + 1);
                    onClicked(newNum);
                }}></div>
            </div>
        </AlpakaPagingButtonStyle>
    );
}

/*
*@Param item = obj
*@Param secretKey = 파라미터 비밀키
*@Param api = api Url
*@Param column = 헤더와 가져올 데이터 array
*@Param btnLimit = 버튼갯수
*@Param limit = 페이지 리스트 갯수
*@Param offset = 페이지 숫자 * 2
*@Param order = 0:desc, 1:asc
*@Param start_date = 시작 날짜
*@Param start_date = 마지막 날짜
*/
export const AlpakaPaging = ({item,secretKey,api,column,btnLimit=5,limit=10,order=0,start_date="",end_date=""})=> {

    const [isPage, setIsPage] = useState(false);
    const [PageList, setPageList] = useState(null);
    const [pageTotal, setPageTotal] = useState(null);
    const [pageNum, setPageNum] = useState(1);
    const [pagingObj, setPageObj] = useState({
        ...item,
        limit,
        offset : 0,
        order,
        start_date,
        end_date,
    });
    useEffect(()=>{
        setPageObj({
                ...item,
                limit,
                offset : 0,
                order,
                start_date,
                end_date,
            }
        )
    }, [item, limit])
    useEffect(()=>{
        const fetchData = async () => {
            const data = await postAPI(api,null, pagingObj)
            if(data.data.length != 0){
                setPageTotal(data.data[0].total);
                setPageList(data.data);
            } else {
                setPageTotal(0);
                setPageList(null);
                setPageTotal(null);
                setPageNum(1);
            }

            setIsPage(true);
            
        };
        fetchData();


        
    },[pagingObj]);

    return <PagingStyle>
            <table border="0" className="working-list">
                <thead>
                    <tr>
                        {column.map((item, idx)=> (
                            <th key={idx}>{item.title}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {isPage && PageList?.map((item, idx)=>(
                        <tr key={idx}>
                            {column.map((obj, i)=> (
                                <td key={i}>
                                    {obj.link == null ?(
                                        <span>{item[obj.column]}</span>
                                    ) : (
                                        <Link to={`${obj.link}?i=${toUrlSafeBase64(CryptoJS.AES.encrypt(item.notice_idx.toString(), secretKey).toString())}`}>{item[obj.column]}</Link>
                                    )}
                                </td>
                            ))}
                            {/*<td>{item.notice_idx}</td>
                            <td><Link to={`/notice/boardEdit?i=${toUrlSafeBase64(CryptoJS.AES.encrypt(item.notice_idx.toString(), secretKey).toString())}`}>{item.notice_title}</Link></td>
                            <td>{item.notice_writer}</td>
                            <td>{item.notice_regdate}</td>
                            <td><span className="state"><span className={
                                (item.notice_state === 1) ? "undecided" : (item.notice_state === 2) ? "edit" : "delete"
                            }></span></span></td>*/}
                        </tr>
                    ))}
                </tbody>
            
            </table>
            {isPage && (
                <AlpakaPagingButton page={pageNum} pageTotal={pageTotal} limit={pagingObj.limit} btnLimit={btnLimit} onClicked={(idx)=> {
                    
                    if(pageNum !== idx){
                        setPageNum(idx);
                        setPageObj((prevObj)=>{
                            return {
                                ...prevObj,
                                offset : limit * (idx-1)
                            }
                        });
                    }

                    
                }} />
            )}
    </PagingStyle>

}