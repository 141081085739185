import { CKEditor } from '@ckeditor/ckeditor5-react';
import MyUploadAdapter from '../../common/component/MyUpload';
import Select from '../../common/component/Select';
import { useLocation } from 'react-router-dom';
import { fromUrlSafeBase64 } from '../../../config/base64';
import CryptoJS from 'crypto-js';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { postAPI, putAPI, s3Save } from '../../../data/data';
import { FOLDER } from '../../../data/port';
import { v4 as uuidv4 } from 'uuid';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { TextEditor } from '../../common/component/Editor/TextEditor';

const EditorStyle =styled.div`
.ck-rounded-corners .ck.ck-editor__editable:not(.ck-editor__nested-editable), 
.ck.ck-editor__editable.ck-rounded-corners:not(.ck-editor__nested-editable){
    min-height: 500px;
    border: 1px solid #ccc;
}
p.sub_title{
    display:block;
    position:relative;
    padding-left: 25px;
    margin-bottom: 15px;
}
p.sub_title::before{
    position: absolute;
    display:block;
    content:"";
    left: 0;
    top: 0;
    width: 6px;
    height: 100%;
    border-radius: 20px;
    background-color: var(--main-color);
}
.btn {
    padding-top: 25px;
    display:flex;
    justify-content: center;
    gap: 30px;
}
.cancel{
    background-color: red;
}
`
const H3Style = styled.h3`
padding-bottom: 100px;
input[type=text]{
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 15px 5px;
}
p.sub_title{
    display:block;
    position:relative;
    padding-left: 25px;
    margin-bottom: 15px;
}
p.sub_title::before{
    position: absolute;
    display:block;
    content:"";
    left: 0;
    top: 0;
    width: 6px;
    height: 100%;
    border-radius: 20px;
    background-color: var(--main-color);
}

` 

export default function BoardEdit(){
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const secretKey = 'notice_idx';
    const editNumber = fromUrlSafeBase64(queryParams.get('i'));
    const bytes = CryptoJS.AES.decrypt(editNumber, secretKey);
    const notice_idx = Number(bytes.toString(CryptoJS.enc.Utf8));
    const [uploadedFiles,setUploadedFiles] = useState([]);
    const [isUpload, setIsUpload] = useState(false);
    const [editorData, setEditorData] = useState(null);
    const editorRef = useRef(null);
    const [item, setItem] = useState({
        title : "",
        content : "",
        regdate : "",
    });

    const upload = ()=>{
        const fetchData = async () => {
            const data = await putAPI("/notice/putNotice", 
            {
                notice_title: item.title, 
                notice_content : editorRef.current.getData(), 
                notice_idx : notice_idx,
                notice_state : 2
            })
            
            if(data.data === "SUCCESS"){
                alert("수정이 완료되었습니다.");
                window.history.back();
            }
           // console.log(data.data);
        };
        fetchData();

    }
    

    useEffect(()=>{
        const fetchData = async () => {
            const data = await postAPI("/notice/getNotice",null, {notice_idx: notice_idx});
            setEditorData(data.data.notice_content);
            setItem((prev)=> {
                return {
                    ...prev,
                    title: data.data.notice_title,
                    content : data.data.notice_content,
                    regdate : data.data.notice_regdate
                }
            });
           // console.log(data.data);
        };
        fetchData();
    }, []);
    
    return <section>
    <div className="container">
        
        <div className="content">
            <h3>게시글 수정</h3>
        </div>

        <div className='content'>
            <div className="main_content">
                <H3Style>
                    <p className='sub_title'>제목</p>
                    <input placeholder='제목' type="text" value={item.title} onChange={(e)=>setItem((prev) => {return {
                    ...prev,
                    title: e.target.value
                }})} /></H3Style>
                
                
                <EditorStyle>
                <p className='sub_title'>내용</p>
                <TextEditor 
                    refs={editorRef}
                    newPath={"notice"} 
                    data={item.content} 
                />
               
                <div className='btn'>
                    <button type='button' onClick={()=>upload()}>수정</button>
                    <button type='button' className='cancel'>취소</button>
                </div>
                </EditorStyle>
                
            </div>
        </div>
    </div>
</section>
}
