import styled from "styled-components";



export const ProductStyle = styled.div`
    .product_paging{
        width: 100%;
        padding: 50px 10px;
    }
    .product_paging ul{
        display: flex;
        justify-content: start;
        gap: 20px;
        flex-wrap: wrap;
    }
    .product_paging ul li{
        width: calc((100% - 80px) / 5);
        cursor: pointer;
        
    }
    .product_paging ul li dt{
        position: relative;
        overflow: hidden;
    }
    .product_paging ul li.sale dt::before{
        content: attr(data-title);
        position: absolute;
        display: block;
        right: -27px;
        top: 11px;
        width: auto;
        height: auto;
        background-color: red;
        color: #fff;
        padding: 3px 30px;
        font-size: 12px;
        transform: rotate(45deg);
    }
    .product_paging ul li dd{
        background-color:#fff;
        box-shadow: 0px 7px 20px -4px rgba(0, 0, 0, .2);
        padding: 10px 5px;
    }
    .product_paging ul li dd p{
        font-size: 18px;
        font-weight: 900;
        margin-bottom: 10px;
    }
    .product_paging ul li dd span{
        display: block;
        text-align: right;
        color: #333;
    }
    .product_paging ul li img{
        width: 100%;
    }
    @media only screen and (max-width: 1290px){
        .product_paging ul li{
            width: calc((100% - 40px) /3);
        }
    }

    @media only screen and (max-width: 400px){
        .product_paging ul li{
            width: 100%;
        }
    }
`