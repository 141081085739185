import { useEffect, useRef, useState } from "react"
import menuArr from "../../../data/menu/menu"
import { Link, useLocation } from "react-router-dom"
import { slideDown, slideToggle, slideUp } from "../component/Slide";



export default function Nav(){

    const ulRef = useRef(null);
    const location = useLocation();
    const path = location.pathname;
    const [isActive, setIsActive] = useState(false);

    
    useEffect(()=>{
        const ul = ulRef.current;
        const li = ul.querySelectorAll("li span.a");
        li.forEach((elem, idx)=>{
            
            elem.addEventListener("click", ()=>{
                const child = elem.parentElement.querySelector("ul");
                if(elem.parentElement.querySelector("ul")){
                    
                    slideToggle(child, 500, elem.parentElement);
                    
                } else {
                    elem.parentElement.parentElement.classList.add("active");
                }
            });



            if(elem.parentElement.querySelector("ul")){
                const child = elem.parentElement.querySelectorAll("li");
                child.forEach((el, i) =>{
                    if(el.classList.contains("active")){
                        elem.parentElement.classList.add("active");
                        slideDown(el.parentElement, 500);
                    }
                })
            } else {
                elem.parentElement.classList.add("active");
            }
        });

    }, [ulRef, slideToggle, slideDown]);
    
    return <nav className="menu">
        <ul ref={ulRef}>
            {menuArr().map((one, index) => (
                
                <li key={index} className={(one.link != "" && path == one.link) ? "active" : ""}>
                    {one.sub.length == 0 ? (
                        <Link to={one.link}><span className="a">{one.title}<i className="dot"></i></span></Link>
                    ) : (
                        <>
                        <span className="a">{one.title}<span className="arrow"><i></i></span></span>
                        <ul>
                            {one.sub.map((sub, i) => (
                                <li key={i} className={(path == sub.link) ? "active" : ""}>
                                    <Link to={sub.link}>
                                    
                                    {sub.title}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                        </>
                    )}
                    
                </li>
            ))}
        </ul>
    </nav>
}