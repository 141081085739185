import React, { useContext, useState } from "react";
import DatePicker from "react-datepicker";
import { ko } from 'date-fns/locale';
import "react-datepicker/dist/react-datepicker.css";
import { addDays, addMonths, addYears, format } from "date-fns";
import Select from "../Select";
import { AlertContext } from "../../../../App";
import { SearchStyle } from "./SearchStyle";
import { FaRegCalendarAlt } from "react-icons/fa";


const SearchTool = ({searchChange, searchRang, calendarView=true}) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [column, setColumn] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [order, setOrder] = useState("");
  const { setIsAlertVisible, setAlertMsg } = useContext(AlertContext);
  const [carView, setCarview] = useState(false);
  const handleSearchClick = () => {
    const change = {
      start_date : (carView) ? startDate : "",
      end_date : (carView) ? endDate : "",
      column: column,
      searchTerm : searchTerm,
      order : order
    }
    searchChange(change);
    
  };

  const alertFunction = (msg) =>{
      setAlertMsg(msg);
      setIsAlertVisible(true);
  }


  const handleSelect = (data) => {
    switch (data.value) {
      case '오늘':
        setStartDate(new Date());
        setEndDate(new Date());
        break;
      case '1주일':
        setStartDate(addDays(new Date(), -7));
        setEndDate(new Date());
        break;
      case '1개월':
        setStartDate(addMonths(new Date(), -1));
        setEndDate(new Date());
        break;
      case '3개월':
        setStartDate(addMonths(new Date(), -3));
        setEndDate(new Date());
        break;
      case '1년':
        setStartDate(addYears(new Date(), -1));
        setEndDate(new Date());
       break;
     default:
       // 기본값 처리
    };  
  };

  const keyHandle = (event)=>{
    if(event.keyCode === 13) handleSearchClick();
  }
  return (
    <SearchStyle>
      <div className="search-container">
        <div className="date-container search-is-box">
          {calendarView && (
            <>
            <p style={{paddingBottom: "20px"}}>기간</p>
            {carView ? (
              <>
                <Select type="button" className="select-button" onSelect={(data)=>handleSelect(data)} >
                  <option value="오늘" selected>오늘</option>
                  <option value="1주일">1주일</option>
                  <option value="1개월">1개월</option>
                  <option value="3개월">3개월</option>
                  <option value="1년">1년</option>
                </Select>
                <div className="date-picker">
                  <DatePicker value={format(startDate,'yyyy년 MM월 dd일')} selected={startDate} onChange={(date) => (endDate < date) ? alertFunction("날짜를 확인해주세요!") : setStartDate(date)} locale={ko} />
                  ~
                  <DatePicker value={format(endDate,'yyyy년 MM월 dd일')} selected={endDate} onChange={(date) => (startDate > date) ? alertFunction("날짜를 확인해주세요!") : setEndDate(date)} locale={ko} />
                </div>
              </>
            ) : (
              <div className="searchTool" onClick={()=>setCarview(true)}>
                <FaRegCalendarAlt style={{fontSize: "38px", color: "var(--main-color)",cursor:"pointer"}} />
              </div>
            )}
            </>
          )}
        </div>

        <div className="search-is-box">
          <p>정렬</p>
          <Select type="button" className="select-button" onSelect={(data)=>setOrder(data.value)} onChange={(e)=>{}}>
            <option value={0} selected>최신</option>
            <option value={1}>과거</option>
          </Select>
        </div>

        <div className="division-container search-is-box">
          <p>검색어</p>
          <Select type="button" className="select-button" onSelect={(data)=>setColumn(data.value)} onChange={(e)=>{}}>
            {searchRang.map((item,idx)=>(
              <option key={idx} value={item.value} selected={(idx === 0) ? "selected": ""}>{item.title}</option>
            ))}
          </Select>
          <input className="input-box" onKeyDown={keyHandle} placeholder="검색어를 입력하세요!" type="text" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
        </div>
        <button className="search-button" onClick={handleSearchClick}>조회</button>
      </div>
    </SearchStyle>
  );
};

export default SearchTool;
