import styled from "styled-components";
import CryptoJS from 'crypto-js';
import Cookies from "js-cookie";
import { fromUrlSafeBase64, toUrlSafeBase64 } from "../../config/base64";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { postAPI } from "../../data/data";
import { addCookie, getCookie, removeCookie, setCookie } from "../common/cookies/cookiesAuth";

const LoginStyle = styled.div`
    .logins_container{
        width:100%;
        height: 100vh;
        display:flex;
        justify-content:center;
        align-items: center;
        padding: 100px 25px;
    }
    .logins_container_inner{
        width: 800px;
        margin: 0 auto;
        background-color:#fff;
        border-radius: 5px;
        box-shadow: 0px 0px 5px #333;
        padding: 50px 25px;
    }
    .logins_title{
        display:flex;
        width: 100px;
        height: 100px;
        border-radius: 50px;
        border: 1px solid #333;
        margin: 0 auto;
        background-color: green;
        color:#fff;
        font-size: 28px;
        justify-content:center;
        align-items: center;
    }
    h2{
        text-align:center;
        margin: 15px 0;
    }
    h2 span{
        color : var(--main-color);
    }
    .logins_content{
        max-height: 210px;
        overflow: auto;
    }
    .logins_content li{
        width: 100%;
        text-align:center;
        padding: 10px 5px;
        border: 1px solid #ccc;
        border-radius: 5px;
        cursor: pointer;
        margin: 10px 0;
    }
    .util ul{
        display:flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding-top: 10px;
        gap: 30px;
    }
    .util ul li:first-child{
        width: 100%;
        padding: 10px;
        text-align:center;
        background-color:var(--main-color);
        color:#fff;
        border-radius: 5px;
        cursor: pointer;
    }
    .util ul li:last-child{
        font-size: 12px;
        color:#ccc;
        cursor: pointer;
    }
    .logins_content p{
        text-align:center;
        font-size:10px;
        color:#333;
    }
`
const Admin = ({name,newName,admin_idx})=> {
    const [siteList, setSiteList] = useState([]);
    const secretKey = "auth";
    const logout = (e)=>{
        e.preventDefault();

        removeCookie("SESSION_SEC");
        alert("로그아웃 되었습니다.");
        window.location.href="/";
    }

    useEffect(()=>{
        const fetchData = async () => {
            const data = await postAPI("/site/getList",null,{admin_idx});
            setSiteList(data.data);
        };
        fetchData();
    }, []);

    const selectedSite =(site_id, site_eg_name,site_name)=>{
        
        const data = {
            site_id : toUrlSafeBase64(CryptoJS.AES.encrypt(site_id, secretKey).toString()),
            folder : toUrlSafeBase64(CryptoJS.AES.encrypt(site_eg_name, secretKey).toString()),
            site_name : toUrlSafeBase64(CryptoJS.AES.encrypt(site_name, secretKey).toString()),
        }

        setCookie("SEC_KEY", data);
                            
        window.location.reload();
    }


    return <LoginStyle>
    <div className="logins_container">
        <div className="logins_container_inner">
            <div className="logins_title">
                <span>{newName}</span>
            </div>
            <h2><span>{name}</span>님 환영합니다!</h2>
            <div className="logins_content">
                <p>운영중인 홈페이지를 선택하세요!</p>
                <ul>
                    {siteList?.map((item,idx)=>(
                        <li key={idx} onClick={()=>{selectedSite(item.site_id, item.site_eg_name, item.site_name)}}>{item.site_name}</li>
                    ))}
                </ul>
            </div>
            <div className="util">
                <ul>
                    <li onClick={()=>alert("준비중입니다.")}>홈페이지 생성하기</li>
                    <li><Link onClick={logout}>로그아웃</Link></li>
                </ul>
            </div>
        </div>
    </div>
</LoginStyle>
}


const Member = ({name,newName,admin_idx})=> {
    const [siteList, setSiteList] = useState([]);
    const secretKey = "auth";
    const logout = (e)=>{
        e.preventDefault();

        removeCookie("SESSION_SEC");
        alert("로그아웃 되었습니다.");
        window.location.href="/";
    }

    useEffect(()=>{
        const fetchData = async () => {
            const data = await postAPI("/site/getList",null,{admin_idx});
            setSiteList(data.data)
        };
        fetchData();
    }, [])
    return <LoginStyle>
    <div className="logins_container">
        <div className="logins_container_inner">
            <div className="logins_title">
                <span>{newName}</span>
            </div>
            <h2><span>{name}</span>님 환영합니다!</h2>
            <div className="logins_content">
                <p>관리중인 홈페이지를 선택하세요!</p>
                <ul>
                    {siteList.map((item,idx)=>(
                        <li key={idx} onClick={()=>{
                            addCookie("SEC_KEY", toUrlSafeBase64(CryptoJS.AES.encrypt(item.site_id, secretKey).toString()));
                            window.location.reload();
                        }}>{item.site_name}</li>
                    ))}
                </ul>
            </div>
            <div className="util">
                <ul>
                    <li onClick={()=>alert("준비중입니다.")}>홈페이지 생성하기</li>
                    <li><Link onClick={logout}>로그아웃</Link></li>
                </ul>
            </div>
        </div>
    </div>
</LoginStyle>
}



export const LoginSuccess =()=>{


    const name = getCookie("SEC_NAME");
    const role = getCookie("SEC_SEQ_KEY");
    const admin_idx = getCookie("SEC_IDX");

    const newName = name.slice(-2);


    return <>
        {role === "ROLE_ADMIN" &&(

            <Admin name={name} newName={newName} admin_idx={admin_idx} />
        )}

        {role === "ROLE_MEMBER" &&(

            <Member name={name} newName={newName} admin_idx={admin_idx} />
        )}
    </>
}